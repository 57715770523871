import { css } from "@emotion/react";

export const Content8Style = css`
  padding: 115px 0 132px 0;
  background-color: #f7fafc;
  display: inline-block;
  width: 100%;
  content_main_text3 p {
    color: #000;
  }

  .content_manager8_list {
    text-align: center;
    margin-top: 70px;
    li {
      position: relative;
      h3 {
        margin-top: 24px;
        font-size: 26px;
        font-weight: bold;
      }
      p {
        margin-top: 5px;
        font-size: 17px;
        color: rgba(63, 68, 96, 0.6);
      }
      .line {
        top: 35%;
        right: -16px;
        position: absolute;
        .cicle {
          width: 4px;
          height: 4px;
          background-color: #c5c5c5;
          float: left;
          margin-left: 4px;
          border-radius: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 55px 0 40px 0;
    .content_main_text3 {
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
    .content_manager8_list {
      margin-top: 20px;
      li:first-of-type {
        margin-top: 0;
      }
      li {
        margin-top: 25px;
        img {
          width: 120px;
        }
        h3 {
          font-size: 18px;
          margin-top: 8px;
        }
        p {
          font-size: 12px;
        }
        .line {
          display: none;
        }
      }
    }
  }
`;
