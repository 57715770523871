import { css } from "@emotion/react";

export const Content4Style = css`
  padding: 140px 0 162px 0;
  background-image: linear-gradient(138deg, #5f41d6, #26fff0 90%, #47f2ff);
  position: relative;
  overflow: hidden;
  width: 100%;
  ::before {
    content: "";
    flex-grow: 1;
    background-color: #000832;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.48;
    z-index: 1;
  }

  .content_main4_content {
    z-index: 10;
    position: relative;
  }
  .content_main_text {
    h3 {
      color: #fff;
    }
  }
  .content_main4_content_list {
    margin-top: 73px;
    text-align: center;
    li {
      display: inline-block;
      // margin-left: 40px; 원래 값인데 그럼 1번째 줄에 li가 두개만 나옴
      margin-left: 30px;
      vertical-align: top;
      text-align: left;
      min-width: 242px;
      h4 {
        span {
          font-size: 63px;
          color: #fff;
        }
      }
      p {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 19px;
        position: relative;
        .line {
          width: 100%;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.6);
          position: absolute;
          top: 12px;
          display: block;
        }
      }
      :nth-of-type(1) .line {
        width: calc(100% - 130px);
        left: 120px;
      }
      :nth-of-type(2) .line {
        width: calc(100% - 160px);
        left: 152px;
      }
      :nth-of-type(3) .line {
        width: calc(100% - 185px);
        left: 180px;
      }
      :nth-of-type(4) .line {
        width: calc(100% - 152px);
        left: 139px;
      }
      :nth-of-type(5) .line {
        width: calc(100% - 152px);
        left: 142px;
      }
      :nth-of-type(4) {
        margin-left: 0;
        margin-top: 54px;
      }
      :nth-of-type(5) {
        margin-top: 54px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0;
    .c_display {
      display: none;
    }
    .container {
      width: 100%;
      overflow: hidden;
      padding: 0 24px;
    }
    .content_main_text {
      h3 {
        font-size: 18px;
        line-height: 1.5;
      }
    }
    .content_main4_content_list {
      margin-top: 32px;
      li {
        text-align: left;
        width: 100%;
        padding: 16px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        margin-left: 0px;

        h4 {
          font-size: 32px;
          float: right;
          width: calc(100% - 100px);
          padding-left: 20px;
          .num {
            font-size: inherit;
            font-weight: inherit;
          }
        }
        p {
          font-size: 12px;
          margin-top: 5px;
          float: left;
          width: 100px;
        }
        :nth-of-type(4) {
          margin-top: 0px;
        }
        :nth-of-type(5) {
          margin-top: 0px;
          .line {
            display: none;
          }
        }
      }
      li:last-of-type {
        border-bottom: 0;
      }
    }
  }
`;
