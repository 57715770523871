/** @jsxImportSource @emotion/react */
import { MainStyle } from "./styled";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

interface UseCaseMainProps {
  id: number;
  onChangeActive: (id: number) => void;
}

const UseCaseMain = ({ id, onChangeActive }: UseCaseMainProps) => {
  const UseCaseMainImg = [
    { id: 1, img: "images/usecase/1_card.png" },
    { id: 2, img: "images/usecase/2_card.png" },
    { id: 3, img: "images/usecase/3_card.png" },
    { id: 4, img: "images/usecase/4_card.png" },
  ];

  return (
    <>
      <div css={MainStyle}>
        <div className="main_content3">
          <div className="main_content_text2">
            <h2>애드머스 마케팅 성공사례</h2>
          </div>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            centeredSlides={true}
            slidesPerView={"auto"}
            initialSlide={id}
            onSlideChange={(swiper) => {
              onChangeActive(swiper.activeIndex);
            }}
          >
            {UseCaseMainImg.map((slide) => (
              <SwiperSlide key={slide.id}>
                <img src={slide.img} alt={`성공사례 이미지 ${slide.id}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default UseCaseMain;
