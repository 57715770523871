import { css } from "@emotion/react";

export const Content3Style = css`
  padding: 108px 0 153px;
  display: inline-block;
  width: 100%;

  .content_manager3_content {
    margin-top: 90px;
    text-align: center;
    li {
      position: relative;
      p {
        font-size: 20px;
        font-weight: 300;
      }
      .line {
        top: 35%;
        right: -12px;
        position: absolute;
        .cicle {
          width: 4px;
          height: 4px;
          background-color: #c5c5c5;
          float: left;
          margin-left: 4px;
          border-radius: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0px;
    .content_main_text3 {
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
    .content_manager3_content {
      margin-top: 18px;
      li:first-of-type {
        margin-top: 0;
      }
      li {
        margin-top: 18px;
        padding-left: 55px;

        img {
          width: 54px;
          float: left;
        }
        p {
          font-size: 14px;
          line-height: 54px;
          padding-left: 18px;
          display: flex;
        }
        .line {
          top: 52px;
          left: 75px;
          // right: inherit;
          .cicle {
            float: none;
            margin-top: 4px;
          }
          .cicle:last-of-type {
            display: none;
          }
        }
      }
    }
  }
`;
