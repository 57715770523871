/** @jsxImportSource @emotion/react */
import { Content7Style } from "./styled";

const Content7 = () => {
  return (
    <div className="content_manager7" css={Content7Style}>
      <div className="container2">
        <div className="content_main_text2">
          <h3>저렴한 금액으로 경품 구매 및 발송</h3>
          <p>
            애드머스에서 엄선한 400여개의 경품들을 저렴한
            <br />
            가격에 선택하여 경품으로 지정하실 수 있습니다.
            <br />
            다양한 추첨 로직을 통해 경품 추첨 및 자동 메시지
            <br />
            발송까지 지원합니다.
          </p>
        </div>
        <div className="content_manager7_img">
          <img src={"images/manager/manager_img_6.png"} alt="content7 이미지" />
        </div>
      </div>
    </div>
  );
};

export default Content7;
