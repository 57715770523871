/** @jsxImportSource @emotion/react */
import { CommonStyle } from "./styles/common";
import { Route, Routes } from "react-router-dom";

import IndexPage from "./pages/MainPage";
import ManagerPage from "./pages/ManagerPage";
import UseCasePage from "./pages/UseCasePage";
import Terms1Page from "./pages/Terms1Page";
import Terms2Page from "./pages/Terms2Page";

const App = () => {
  return (
    <div className="App" css={CommonStyle}>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/manager" element={<ManagerPage />} />
        <Route path="/use_case" element={<UseCasePage />} />
        <Route path="/terms" element={<Terms1Page />} />
        <Route path="/terms2" element={<Terms2Page />} />
      </Routes>
    </div>
  );
};

export default App;
