/** @jsxImportSource @emotion/react */
import { TermsStyle } from "./styled";

const Terms2 = () => {
  return (
    <div css={TermsStyle}>
      <div className="main_content2">
        <div className="container2">
          <div className="main_content_text3">
            <h2>이용 약관</h2>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="content_temrs">
            <p style={{ textAlign: "center" }}>
              <strong>
                <span style={{ fontSize: "11pt", lineHeight: " 115%" }}>
                  애드머스 이용약관
                </span>
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 1 장 총 칙
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 1 조 (목 적)
                </span>
              </strong>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                이 서비스 약관은 지니웍스 주식회사(이하 "회사"라 합니다)가
                제공하는 애드머스 서비스를 이용하는데 필요한 회원과 회사간의
                권리 및 의무 기타 제반사항을 규정함을 목적으로 합니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                }}
              >
                <br />
                <br />
              </span>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 2 조 (용어의 정의)
                </span>
              </strong>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <br />
                1. "회원"이라 함은 서비스에 접속하여 이 약관에 동의하고
                서비스에서 아이디(ID)와 비밀번호(PASSWORD)를 발급받은 국내
                개인사업자 또는 법인 사업자와 개인가입자를 말합니다.
              </span>
            </p>
            <ol start={2}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스 서비스"라 함은 "애드머스 매니저", "기프트 서비스",
                  "메시지 서비스" 등 "회사"에서 제공하는 일체의 서비스(이하
                  "서비스"라 함)를 의미합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스 매니저"라 함은 "회원"이 "이벤트"의 신청, 집행, 관리,
                  취소 등을 위해 "회사"가 제공하는 관리사이트를 의미합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  이벤트"라 함은 애드머스 매니저를 통해 생성되어 "이벤트
                  참여자"가 접속하여 참여할 수 있는 이벤트를 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  이벤트 참여자"라 함은 "이벤트"에 접속하는 고객을 의미합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "기프트 서비스" 라 함은 회사가 제휴한 유선인터넷 또는
                  무선인터넷을 통해 실물 상품을 선물할 수 있는 서비스로,
                  대량발송을 원하는 법인/개인 회원을 위해 모바일 기프트 서비스를
                  제공하는 서비스를 말합니다. <br />
                  7. "모바일 기프트"라 함은 교환처에서 판매하는 일정한 상품을
                  전송자가 휴대폰 Mobile 이미지 쿠폰 형태로 수신자에게 선물하고,
                  이 쿠폰을 수신자가 해당 교환처에서 연계된 상품으로 교환할 수
                  있는 서비스 또는 선물을 보냈을 때 선물을 수신하는 고객의
                  휴대폰으로 전송되는 Mobile 쿠폰을 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "수신자"라 함은 모바일 기프트를 전송받은 자를 말합니다.
                  <br />
                  9. "교환처"라 함은 모바일 기프트로 실제 상품을 교환할 수 있는
                  매장을 말합니다.
                  <br />
                  10. "발송취소"라 함은 수신자가 정상적으로 모바일 기프트를
                  수신하지 못하고 실패된 경우 발송을 취소하는 것 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  메시지 서비스"라 함은 컴퓨터 서버 또는 클라우드 컴퓨팅
                  환경에서 이용할 수 있도록 "회사"에서 제공하는 메시지 시스템을
                  이용하여 유무선 통신망을 통해 단말기 또는 유무선단말기나
                  서버에 메시지를 송수신하는 서비스 및 그와 관련된 부가 서비스
                  일체를 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  무선 통신망"이라 함은 전선을 사용하지 않고 전자기파를 이용하여
                  이동 전화 등이 수신할 수 있도록 정보 전달할 수 있게 하는
                  통신의 설비나 조직을 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  망사업자"라 함은 "메시지 서비스"의 제공을 위해 사용하는 "무선
                  통신망"을 운영하는 유무선 통신사업자를 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  스팸"이라 함은 "무선 통신망"을 통해 수신자의 동의 없이
                  일방적으로 전송되는 영리목적의 광고성 정보를 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  불법 스팸"이라 함은
                  정보통신망이용촉진및정보보호등에관한법률(이하
                  "정보통신망법")을 위반하여 전송되는 영리목적의 광고성 정보를
                  말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  문자피싱"이라 함은 메시지를 통해 발생하는 전자금융사기를
                  말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  발신번호"라 함은 메시지 전송 시 첨부하는 보내는 사람을
                  식별하는 번호(또는 회신번호)를 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "포인트"란 "회원"이 신용카드∙계좌이체 등의 결제수단으로 선
                  결제하여 애드머스 사이트 내부에서 사용할 수 있는 포인트를
                  말하며, 그 상세한 내용은 제16조를 따릅니다.
                  <br />
                  19. "환불"이라 함은 환불 신청 건에 한해 결제한 상품대금을 현금
                  또는 포인트로 지급하는 것을 말합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "정산"이라 함은 "회원"이 애드머스 사이트 내부에서 사용한
                  금액에 대한 상품 대금을 회사에 지급하는 것과 지급한 금액의
                  증명 자료를 주고받는 업무를 말합니다.
                  <br />② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는
                  것을 제외하고는 관계법령, 상관례 및 서비스별 안내에서 정하는
                  바에 의합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 3 조 (약관의 적용과 개정)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 본 "약관"을 "홈페이지"
                  <span style={{ background: "yellow" }}>
                    (http://www.admerce.com)
                  </span>
                  에 게시하는 방법으로 공지하며 "서비스" 이용 신청(가입) 시
                  "회원"으로부터 "약관"에 대한 동의를 받습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "약관의규제에관한법률", "정보통신망법"등 관련법을
                  위배하지 않는 범위에서 본 "약관"을 개정할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"가 본 "약관"을 개정하는 경우에는 적용일자 및 개정사유를
                  명시하여 그 적용일자 30일 이전부터 적용일자 전일까지
                  공지합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"가 전항에 따라 개정 약관을 공지 또는 통지하면서 30일 기간
                  내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는
                  뜻을 "회원"에게 명확하게 공지 또는 통지하였음에도 "회원"이
                  명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이 개정
                  약관에 동의한 것으로 봅니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 개정 약관의 적용에 동의하지 않는 경우 "회사"는 개정
                  약관의 내용을 적용할 수 없으며, 이 경우 "회원"은 계약을 해지할
                  수 있습니다. 다만, 기존 "약관"을 적용할 수 없는 특별한 사정이
                  있는 경우에는 "회사"는 이용계약을 해지할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  새로운 "서비스"가 개설될 경우 별도의 명시된 설명이 없는 한 본
                  "약관"에 따라 제공합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "서비스"에 부수하여 타 서비스와 연계한 통합서비스 또는
                  별도의 서비스 등을 제공할 수 있으며 이용조건 등은 별도로
                  공지하는 바에 따릅니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 4 조 (약관 외 준칙)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  본 "약관"은 "회사"가 제공하는 "서비스"에 관하여 별도의 정책 및
                  운영규칙과 함께 적용됩니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  본 "약관"의 해석은 대한민국 법에 의하여 해석되고 적용되며,
                  "약관"에서 정하지 아니한 사항은 전기통신기본법, 전기통신사업법
                  및 기타 관계법령 또는 상관례에 따릅니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 5 조 (개인정보취급방침의 적용)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 관계법령이 정하는 바에 따라 "서비스" 이용 신청(계약)을
                  위해 수집하는 "회원"의 개인정보를 보호하기 위하여 노력을
                  합니다. 단, "회원"이 게시판 등 온라인상에서 자발적으로
                  제공하는 개인정보는 다른 사람이 수집하여 사용할 가능성이
                  있으며 이러한 위험은 개인에게 책임이 있고 "회사"는 책임이
                  없습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회사"가 제공하는 "서비스"의 "회원"을 대상으로
                  "서비스"의 양적, 질적 향상을 위하여 "회원"의 개인 식별이
                  가능한 개인정보를 "회원"의 동의를 받아 수집하여 "서비스"에
                  이용할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"가 수집하는 개인정보는 "서비스"의 제공에 필요한
                  최소한으로 하되, 필요한 경우 자세한 정보를 요구할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 개인정보의 수집 시 관련 법규에 따라 "약관"에 그 수집
                  범위 및 목적을 사전 고지하며 "회사"의 "개인정보취급방침"
                  <span style={{ background: "yellow" }}>
                    (http://www.admerce.com/information/privacy)
                  </span>
                  에 공개합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 제공한 개인정보는 "회원"의 동의 없이 제3자에게
                  누설하거나 제공하지 않습니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 2 장 서비스 이용 계약
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 6 조 (이용계약의 성립)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"는 제2조 제1항 제1호의 "회원"이 "애드머스 매니저"를
                  사용하여 각자의 고객에게 이벤트를 제공하는 서비스입니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>2. "</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"를 이용하고자 하는 "회원"은 본 "약관", "개인정보 수집
                  및 이용", "스팸메시지 관리 규정"에 동의하여 신청할 수
                  있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>3. "</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스" 이용 신청(계약)은 "회사"가 "회원"이 제출한 구비 서류와
                  기타 자격 조건을 확인하고 이용 신청(계약)을 승인함으로써
                  성립합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"를 이용하고자 하는 "회원"은 다음 각 호의 서류를 직접
                  또는 우편이나 "회사"가 인정하는 방식으로 제출하여야 합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 사업자등록증 사본
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 기타 "회사"가 "회원"에게 요청하는 서류(필요 시)
              </span>
            </p>
            <ol start={5}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "회사"는 "회원"의 업종, 이용 형태 등에 따라 별도의 동의서를
                  요구하거나 부속계약서를 체결할 수 있으며 이를 위하여 "회원"의
                  개인정보를 이용할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "스팸", "불법스팸" 및 "문자피싱" 발송 등으로 "서비스"
                  계약을 해지한 "회원"의 "서비스" 이용 신청(계약)에 대한 승낙을
                  유보하기 위하여 법인명, 대표자명, 사업자 등록번호, 전화번호,
                  해지사유 등의 정보를 수집, 보관할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스" 이용을 위하여 "회원"은 "회원" 본인의 명의를 증명할 수
                  있는 발신번호를 서비스 이용 전에 등록하여야 합니다. 본인
                  명의(또는 실명)의 발신번호를 사전에 등록하지 않은 "회원"은
                  법적인 보호를 받을 수 없으며, 서비스 이용의 제한을 받을 수
                  있습니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 7 조 (이용제한)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원"이 다음 중 하나에 해당하는 경우 당해 "서비스"의
                  이용을 정지할 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 하나의 ID를 복수 개의 사업자 또는 개인이 공동으로 사용하는
                경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 부정결제가 의심되는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③ 전 동의 없이 서비스를 도용(자체 이미지 제작, 유효기간 임의
                조정 등)하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ④ "회원"의 "서비스" 사용 목적이 명백하게 사회질서 및 미풍양속에
                반하는 경우
              </span>
            </p>
            <ol start={2}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 제 1항의 규정에 의하여 "서비스" 이용을 정지하는 경우,
                  그 사실을 당해 "회원"에게 통지합니다. 다만 미리 통지하는 것이
                  곤란한 경우에는 이용정지 후 통지할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"의 이용제한이 있는 경우 "회사"는 서비스 이용료에서
                  "회원"이 "서비스"의 이용을 통해 얻은 이익 및 "회사"의 손해액과
                  환급수수료(20%", 부가세 별도)를 공제한 금액을 환급하여
                  드립니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 3 장 회원가입 계약
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 8 조 (회원가입)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"를 이용하고자 하는 자는 "회사"가 정한 다음의 가입
                  절차에 따라 회원 가입 신청을 해야 합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ①. "회원"은 본 약관을 읽고 "동의" 버튼을 누르거나 "확인" 등에
                체크하는 방법을 취한 경우 본 약관에 동의한 것으로 간주합니다.
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ②. 본 약관 동의 후 "회원"이 가입신청서 작성 및 사업자를
                입력합니다. 이때 사업자 확인 절차를 거치며, 회사는
                범용전자공인인증서를 통한 가입인증 절차를 추가할 수도 있습니다.
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③. "회원"은 "회사"의 요청이 있을 시 "회원" 등록에 필요한 서류를
                제출합니다.
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ④. "회사"는 "회원"의 서류 검토 이후 적격한 "회원"이라고 판단될
                경우에 "회원"이 신청한 아이디 및 비밀번호에 승인을 하며,
                "회원"은 "회사"의 승인 절차 이후에 "서비스" 이용이 가능합니다.
              </span>
            </p>
            <ol start={2}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 다음 중 하나에 해당하는 경우 "회원" 등록의 승인을
                  유보하거나 거절할 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ①. 타인 명의로 서비스를 이용하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ②. "회사"의 서비스 정책 또는 본 이용약관에 위반하여 서비스를
                이용하고자 하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③. 필수자료 및 정보를 제출하지 않거나 허위로 제출하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ④. 기타 "회사"가 관련법령 등을 기준으로 하여 명백하게 사회질서
                및 미풍양속에 반할 우려가 있음을 인정하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑤. 해외 사업자인 경우 및 18세 미만의 개인사업자인 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑥. 계약을 해지했던 이"회원"이 다시 신청을 하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑦. 기타 관련 법령이나 "회사"가 정한 조건에 위배되는 행위를 한
                경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 9 조 (이용계약의 해지)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 본 이용 계약을 해지하고자 하는 때에는 언제든지 "회원"
                  정보 관리 페이지에서 "회사"가 정한 절차에 따라 "회원"의 ID를
                  삭제하고 탈퇴 할 수 있습니다. 단, "이벤트" 및 "메시지" 예약
                  대기 상태인 경우에는 예약 취소 및 완료 이후 탈퇴가 가능합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 제 18조의 규정을 위반한 경우 "회사"는 일방적으로 본
                  계약을 해지할 수 있고, 이로 인하여 "서비스" 운영에 손해가
                  발생한 경우 이에 대한 민,형사상 책임도 물을 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 제2항의 규정에 의하여 "서비스" 계약을 해지하는 경우,
                  그 사실을 당해 "회원"에게 통지합니다. 다만 미리 통지하는 것이
                  곤란한 경우에는 계약해지 후 통지할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 서비스를 이용하는 도중, 연속하여 이(2)년 동안 서비스에
                  log-in한 기록이 없는 경우 "회사"는 "회원"의 회원자격을
                  상실시킬 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 10 조 (회원에 대한 통지)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이
                  없는 한 "회원"이 제공한 전자우편 또는 전화 등으로 할 수
                  있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 홈페이지의
                  초기화면에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
                  다만, "회원" 본인의 거래와 관련하여 중대한 영향을 미치는
                  사항에 대하여는 제1항의 통지를 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 연락 가능한 전자우편 주소 및 휴대전화번호 등을
                  "회사"에 제공하지 않거나 이를 유효한 것으로 갱신하지
                  않음으로써 통지 받지 못하거나 "회원"이 "회사"의 연락을 고의
                  또는 과실로 수신하지 아니하여 발생하는 "회원"의 손해에 대하여
                  "회사"는 일체의 책임을 부담하지 않습니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 4 장 서비스 이용
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 11 조 (서비스의 내용)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"는 다음의 각 호와 같이 구분됩니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 애드머스 매니저: "회원"이 이벤트를 생성 및 관리하는 서비스를
                말합니다. "회원"은 "애드머스 매니저"에서 제공하는 게임 및 경품을
                선택하여 고객이 참여할 수 있는 이벤트를 진행할 수 있습니다.
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 메시지 센터: "회원"이 "애드머스 매니저"를 통해 생성한 게임 및
                경품 이벤트를 "회원"이 설정한 고객에게 메시지로 발송하고 발송
                내역을 관리하는 서비스를 말합니다.
              </span>
            </p>
            <ol start={2}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "서비스"의 사용 가이드, 가격 기준, 기타 서비스 내용을
                  "애드머스 매니저"에서 자세하게 공시할 수 있으며, "회원"은
                  "서비스"의 구매 전 이를 신중히 확인하여야 합니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 12 조 (서비스 이용절차)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  본 약관 제 8 조에 따라 "서비스" 이용 승인을 받은 "회원"은
                  "애드머스 매니저"에 접속하여 "회사"로부터 부여 받은 "회원"
                  아이디 및 비밀번호를 입력하고 로그인합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 포인트 충전 금액에 대하여 결제를 신청합니다. 결제
                  방법은 신용카드, 계좌이체, 현금입금의 방법을 선택합니다. 이 때
                  "회사"는 "회원"에게 제 8 조의 등록 당시 인증 받은
                  범용전자공인인증서를 통해 "회원" 확인을 요청할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  구매결제가 완료되면 "회원"은 충전된 포인트를 활용하여
                  "서비스"를 사용할 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 13 조 (서비스의 이용시간)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만,
                  "회사"의 업무상이나 기술상의 이유로 "서비스"가 일시 중지될 수
                  있고, 또한 운영 상의 목적으로 "회사"가 정한 기간에는
                  "서비스"가 일시 중지될 수 있습니다. 이러한 경우 "회사"는
                  사전에 이를 공지하며, 부득이한 경우 사후 통보할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "서비스"를 일정 범위로 분할하여 각 범위 별로 이용
                  가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을
                  공지합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 14 조 (서비스 변경 및 중단)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "서비스"의 내용, 품질 또는 기술적 사양 등에 대해
                  "회사"의 합리적 판단에 따라 변경할 수 있습니다. 이 경우에는
                  변경되는 "서비스"의 내용 및 제공일자를 명시하여 제공일자로부터
                  15일 전에 미리 "애드머스 매니저" 초기화면 등에 미리
                  공지합니다. 단, 변경 내용이 "회원"에게 불리한 변경의 경우에는
                  적용일자 30일 이전부터 적용일자 전일까지 공지합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 다음 각 호에 해당하는 경우 본 서비스의 전부 또는
                  일부를 제한하거나 중단할 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① "서비스"용 설비의 보수 등 공사로 인한 부득이한 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② "회원"이 회사의 영업활동을 방해하는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③ 서비스 제공업자와의 계약 종료 등과 같은 회사의 제반 사정으로
                본 "서비스"를 유지할 수 없는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ④ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
              </span>
            </p>
            <ol start={3}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 전항의 변경 내용에 동의하지 않을 경우 이용계약의
                  해지를 요청할 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 15 조 (서비스 이용료 등)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 포인트를 선충전(선결제)하는 방식으로 정산이
                  이뤄집니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스" 이용료는 "회사"에 의해 변경될 수 있으며, 변경 1개월
                  전에 웹페이지와 "회원" 정보로 등록된 이동전화 번호와 E-Mail을
                  통해 충분히 사전 고지합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  변경된 정책에 동의하지 않는 "회원"은 "회사"가 정한 절차에 따라
                  탈퇴할 수 있습니다. 다만, "회원"이 변경된 이용료의 적용
                  일로부터 15일 이내에 "회사"에 대해 탈퇴의 의사 표시를 하지
                  아니하는 경우에는 변경된 이용료에 동의한 것으로 간주합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 16 조 (충전금의 납입과 환급 및 환불)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  포인트"는 1,000원 단위로 최소 10,000원부터 충전할 수 있으며,
                  "포인트" 1원은 현금 1원의 의미를 지닙니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 보유할 수 있는 최대 "포인트"는 2억원입니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  포인트" 충전에 대한 결제 취소는 7일 이내에만 가능하며, 7일
                  이후부터는 본 조 제 4항에 따라 환불 규정이 적용됩니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  환불할 수 있는 최소 단위는 1,000원이며, 환불하고자 하는
                  "포인트"의 일정 금액이 환불 수수료로 차감되고 잔여 "포인트"가
                  환불됩니다. 환불규정은 아래와 같습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 1,000원 미만: 환불 불가
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 1,000원 이상 ~ 5백만원 미만: 환불 수수료 10%"
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③ 5백만원 이상 ~ 2억원 이하: 환불 수수료 5%"
              </span>
            </p>
            <ol start={5}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  환불 요청 후 2주 후에 "회원"이 고객센터에 전달한 입금 희망
                  계좌로 환불됩니다. "회원"이 환불을 요청했을 때 "회사"는
                  "회원"에게 본인 인증 및 환불 계좌 관련 자료를 요구할 수
                  있으며, "회원"은 이에 협조해야 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  포인트"는 마지막으로 이용(환불, 충전, 사용)한 날로부터{" "}
                  <span style={{ background: "yellow" }}>60</span>
                  <span style={{ background: "yellow" }}>개월이</span> 지나는
                  동안 단 한 차례도 이용하지 않으면 자동으로 소멸됩니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 5 장 계약 당사자의 의무
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 17 조 (회사의 의무)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를
                  하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여
                  최선을 다하여 노력합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  회사"는 "서비스" 이용과 관련하여 "회원"으로부터 제기된
                  의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야
                  합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원" 정보를 철저히 보안 유지하며, "회원"의 승낙 없이
                  제 3자에게 누설, 배포하지 않는 것을 원칙으로 합니다. 단,
                  아래의 사항에 해당하는 경우는 제외합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 전기통신기본법 등 법률의 규정에 의해 국가기관의 적법한 절차에
                의한 요구가 있는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 범죄에 대한 수사상의 목적으로 유관기관의 요청이 있는 경우
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③ 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우
              </span>
            </p>
            <ol start={4}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스"가 장애에 의해 중단되는 경우, 회사는 웹페이지에 공지 및
                  그 사실을 전화, 전자우편 등 신속한 방법으로 "회원"에게
                  전달하고 복구를 위하여 최선의 조치를 강구해야 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원"이 "서비스"를 이용함에 있어 "회사"의 고의 또는
                  중과실로 인한 손해가 발생할 경우 그 손해를 배상할 의무가
                  있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                  }}
                >
                  회사"는 "서비스" 및 "서비스"의 이용과 관련하여 "회사"의 고의
                  또는 중과실이 없이 "회원"에게 발생한 일체의 손해에 관하여
                  책임을 부담하지 않습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "서비스" 제공목적에 맞는 서비스 이용 여부를 확인하기
                  위하여 상시적으로 모니터링을 실시합니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 18 조 (회원의 의무)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 본인이 진행한 이벤트로부터 접수되는 고객 문의 및
                  클레임 등을 직접 처리합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와
                  관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을
                  준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는
                  안 됩니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 "회원계정"을 "서비스"를 이용하기 위한 용도(이벤트관리,
                  고객정보 관리, 이용료 결제 등)로만 사용하여야 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 "회사"가 제공하는 "서비스"의 모든 정보 및 "회사"의
                  기술 권한에 대해 "회사"의 승인 없이 복사, 복제, 수정, 번역하여
                  제 3자에게 배포할 수 없습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 "서비스" 계약에 필요한 개인신상정보 등을 "회사"에
                  허위로 제공하여서는 아니되며, 정보변경 시 지체없이 "회사"에
                  통보하여 갱신하여야 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 "정보통신망법"에 따른 광고성 정보 전송 시 의무사항 및
                  "회사"의 약관을 준수하여야 하며, 기타 "회사"의 업무 수행에
                  현저한 지장을 초래하는 행위를 하여서는 안됩니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 공공의 안녕, 질서 또는 미풍양속을 해하는 다음 각 호의
                  내용으로 이벤트를 진행하여서는 안됩니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 폭력적, 외설적이거나 누드, 명시적인 폭력이나 성적인 내용 또는
                폭력 또는 성행위 묘사가 포함된 콘텐츠를 포함하여 선정적, 신성
                모독적, 외설적 또는 음란한 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 다른 사람을 괴롭히거나, 끈질기게 접근하거나 위협하기 위해
                사용되는 콘텐츠를 포함하여 괴롭힘, 위협, 증오 관련, 학대, 선동적
                또는 불쾌감을 주는 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③ 벼락부자 되기, 연쇄 편지 또는 피라미드 기획 등의 기만적인
                주장, 사업 관행을 포함하는 허위 또는 오해의 소지가 있는 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ④ 중상, 명예 훼손, 고의적인 거짓 또는 다른 사람을 잘못 표현하는
                콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑤
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                시스템, 프로그램, 데이터 또는 개인 정보를 손상하거나 방해하거나
                비밀리에 가로채거나 소유권을 빼앗을 수 있는 전파를 포함하여
                "회사" 또는 다른 당사자의 시스템 및 네트워크에 유해한 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑥ 제3자의 저작권, 상표, 상표명, 영업 비밀 또는 특허를 포함하여
                제3자의 지적 재산권을 침해하는 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑦
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                기밀 유지 의무를 위반하는 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑧ 제3자의 사생활, 평판, 도덕 또는 기타 권리를 침해하는 콘텐츠
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑨
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                다음 중 하나 이상을 권장하는 콘텐츠: 주류, 담배 제품 및 관련
                도구, 불법 처방전 또는 기분 전환용 약제 및 관련 도구, 무기, 탄약
                또는 폭발물
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ⑩
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                불법적인 제품, 서비스 또는 활동을 장려하거나 관련 법률, 규정
                또는 정부 정책 위반을 조장하는 콘텐츠, 또는
              </span>
            </p>
            <ol start={8}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 "전기통신망법"에 따라 메시지 전송을 위해 이동통신사
                  가입자의 사전 수신동의를 직접 얻어야 하고, "스팸", "불법스팸"
                  및 "문자피싱" 전송을 위해 "서비스"를 이용하여서는 아니되며,
                  "회사"를 통해 전달되는 수신거부 요청에 대해서는 즉각적으로
                  처리하고 24시간 이내에 처리결과를 "회사"에 회신하여야 하며 본
                  항의 위반으로 발생하는 모든 민 형사사의 책임은 "회원"이 직접
                  부담해야 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &ldquo;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 어떠한 경우에도 "회사"의 사전 승낙 없이
                  에이전트(agent), 로봇(robot), 스크립트(script),
                  스파이더(spider), 스파이웨어(spyware) 등의 자동화된 수단 또는
                  기타 부정한 방법 등을 통하여 다음 각호와 같은 행위 등을 하거나
                  그러한 시도를 하지 않을 의무가 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ① 서비스에 접속하는 행위
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ② 노출횟수 및 클릭횟수를 부정하게 생성하거나 증가시키는 행위
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ③ 회사의 서버 및 설비 등에 부하를 야기하는 행위
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                ④ 기타 서비스의 정상적인 운영을 방해하는 행위
              </span>
            </p>
            <ol start={10}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 "서비스" 제공 또는 본 조 위반여부를 확인하기 위해
                  "회사"가 자료 또는 접근권한의 제공 및 관련사실에 대한 소명을
                  요청하는 경우에는 이에 성실히 임해야 할 의무가 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 아이디(ID)와 비밀번호(PASSWORD)를 철저히 관리해야
                  하며, 관리소홀, 부정사용 등에 의하여 발생하는 모든 결과에 대한
                  책임은 "회원" 본인이 부담하며, "회사"는 이에 대해 어떠한
                  책임도 부담하지 않습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"은 본인의 아이디(ID)와 비밀번호(PASSWORD)를 제3자에게
                  이용하게 해서는 안 되며, "회원" 본인의 아이디(ID)와
                  비밀번호(PASSWORD)를 도난 당하거나 제3자가 사용하고 있음을
                  알았을 때에는 즉시 비밀번호(PASSWORD)를 변경해야 하며, 해당
                  사실을 "회사"에 통지하고 "회사"가 안내하는 바를 따라야 합니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 6 장 손해배상 등
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 19 조 (손해배상)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원"이 "서비스"를 이용함에 있어 회사의 고의 또는
                  과실로 인해 손해가 발생한 경우에는 민법 등 관련 법령이
                  규율하는 범위 내에서 그 손해를 배상합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"이 본 약관을 위반하거나 불법행위로 인하여 발생하는 모든
                  문제는 "회원"에게 책임이 있습니다. 이로 인해 발생한 "회사",
                  고객 또는 제 3자의 손해에 대하여 "회원"은 손해액 전액을
                  배상하여야 합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  본 조 제 2항으로 "회사"가 고객 또는 제 3자로부터 손해배상 청구
                  또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 "회원"은
                  자신의 책임과 비용으로 "회사"를 면책 시켜야 하며, "회사"가
                  면책되지 못한 경우 당해 "회원"은 그로 인하여 "회사"에 발생한
                  모든 손해를 배상하여야 합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 20 조 (면책사항)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여
                  "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이
                  면제됩니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원"의 귀책사유로 인한 "서비스"의 이용장애에 대하여
                  책임을 지지 않습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "회원" 상호간 또는 "회원"과 제3자 상호간에 "서비스"를
                  매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한
                  손해를 배상할 책임도 없습니다.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 7 장 기타
                </span>
              </strong>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 21 조 (회원의 개인정보보호)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 관련 법령이 정하는 바에 따라 "회원"의 개인정보를 수집
                  ・이용하며, 관련 법령에서의 예외를 제외하고는 "회원"의 동의
                  없이 제3자에게 개인정보를 제공하지 않습니다. "회사"는 "회원"의
                  개인정보를 보호하기 위하여 최선의 노력을 다하고 있으며,
                  "회원"의 개인정보 보호에 관한 사항은 관계 법령과 "회사"가
                  정하는 『개인정보처리방침』을 따릅니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회사"는 "서비스" 이용기간 중에 "회원"의 개인정보를 보유
                  ・이용하고 "회원"이 탈퇴하거나 개인정보 이용목적을 달성한 경우
                  또는 일정기간 "서비스"를 이용하지 않아 개인정보의 유효기간이
                  도래하는 경우 수집된 개인정보를 열람하거나 이용할 수 없도록
                  파기 처리하며, 개인정보의 유효기간 만료 최소 30일전까지 해당
                  사실을(이메일 ・SMS중 택1) 공지 합니다. 개인정보의 유효기간은
                  "회원"이 당사의 "서비스"를 이용하지 않은 최근의
                  기록(로그인)으로부터 기산되며, 전자상거래법 제6조 제3항 및 동
                  법 시행령 제6조 제1항에 따라 5년으로 설정됩니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 22 조 (양도금지)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원"과 "회사"는 이 약관상의 지위 또는 권리 ・의무의 전부 또는
                  일부를 제3자에게 양도 ・위임하거나 담보 제공 등의 목적으로
                  처분할 수 없습니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  제 23 조 (관할법원)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스" 이용과 관련하여 "회사"와 "회원" 사이에 분쟁이 발생한
                  경우, "회사"와 "회원"은 분쟁의 해결을 위해 성실히 협의합니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는
                  민사소송법상의 관할법원에 소를 제기할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  "
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  서비스" 이용 및 본 약관과 관련하여 발생하는 분쟁에 대해서는
                  대한민국의 법률을 적용합니다.
                </span>
              </li>
            </ol>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  fontFamily: "맑은 고딕",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                // textAutospace: "ideographNumeric", "ideographOther",
                wordBreak: "keep-all",
              }}
            >
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p style={{ textAlign: "center" }}>
              <strong>
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: " 115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보처리위탁 약정
                </span>
              </strong>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: " 115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스 회원(이하 "위탁자"라 한다)가 개인정보 처리업무를
                회사(이하 "수탁자"라 한다)에게 위탁함에 있어 다음과 같은
                내용으로 본 업무 위탁 약정(이하 "본 약정"이라 한다)을 체결한다.
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p>- 다 음 &ndash;</p>
            <p>&nbsp;</p>
            <p>
              <strong>
                <span style={{ background: "white" }}>제 1 조 (목적)</span>
              </strong>
            </p>
            <p>
              <span style={{ background: "white" }}>
                본 약정은 "위탁자"가 개인정보처리업무를 "수탁자"에게 위탁하고,
                "수탁자"는 이를 승낙하여 "수탁자"의 책임아래 성실하게 업무를
                완성하도록 하는데 필요한 사항을 정함을 목적으로 한다.
              </span>
            </p>
            <p>
              <span style={{ background: "white" }}> </span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>
                  제 2 조 (용어의 정의)
                </span>
              </strong>
            </p>
            <p>
              <span style={{ background: "white" }}>
                본 약정에서 별도로 정의되지 아니한 용어는 개인정보보호법, 동법
                시행령 및 시행규칙, 「표준 개인정보 보호지침」(행정자치부고시
                제2016-21호)에서 정의된 바에 따른다.
              </span>
            </p>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>제 3 조 (약정기간)</span>
              </strong>
            </p>
            <p>
              <span style={{ background: "white" }}>
                본 약정의 기간은 별도로 정하지 않은 한 본 약정의 원인이 되는
                "위탁자"와 "수탁자" 사이의 계약(이하 "원 계약"이라 함)의
                유효기간과 동일하다.
              </span>
            </p>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>
                  제 4 조 (위탁업무의 목적 및 범위)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 본 약정이 정하는 바에 따라 애드머스 서비스 이용
                  제공을 위한목적으로 다음의 개인정보 처리 업무(이하
                  "위탁업무")를 수행한다.
                </span>
                <br />
                <span style={{ background: "white" }}>①</span>
                <span style={{ background: "white" }}>
                  "위탁자"의 이벤트를 진행함에 있어 취급하는 고객 개인정보 일체
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자는 위탁업무를 수행함에 있어 개인정보보호법 및 동법
                  시행령 등 관련 법령을 성실히 준수한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 위탁업무를 수행함에 있어 "위탁자"로부터 필요한
                  최소한의 개인정보만 제공받으며, "위탁자"로부터 제공받은 정보의
                  관리 및 처리기간은 위탁계약의 종료 시까지로 한다.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>
                  제 5 조 (양도 및 재위탁 금지)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 "위탁자"의 사전 승낙을 얻은 경우를 제외하고
                  "위탁자"와의 계약상의 권리와 의무의 전부 또는 일부를 제3자에게
                  양도하거나 재 위탁할 수 없다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  다만, 부득이하게 제3자에게 위탁하여야 하는 경우 "수탁자"는
                  재위탁하고자 하는 업무의 목적, 범위, 위탁되는 개인정보의 종류,
                  재 위탁을 받는 자의 인력과 물적 시설, 재정부담능력, 기술
                  보유의 정도 등을 명시하여 반드시 "위탁자"로부터 사전 서면
                  동의를 득하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"가 재위탁을 하는 경우 "수탁자"는 개인정보의 업무위탁과
                  관련한 개인정보보호법, 동법 시행령 및 시행규칙, 고시 기타
                  관계법령의 규정을 준수해야 하고, 재위탁을 받은 자가 위
                  규정들을 위반하지 않도록 관리 ・감독해야 한다.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>
                  제 6 조 (개인정보의 안전성 확보조치)
                </span>
              </strong>
            </p>
            <p>
              <span style={{ background: "white" }}>
                "수탁자"는 다음 각 호와 같이 개인정보의 안전성 확보에 필요한
                관리적, 기술적, 물리적 조치를 취하여야 한다.
              </span>
            </p>
            <p>
              <span style={{ background: "white" }}>①</span>
              <span style={{ background: "white" }}>
                개인정보의 안전한 처리를 위한 내부 관리계획의 수립 ・시행
              </span>
              <br />
              <span style={{ background: "white" }}>②</span>
              <span style={{ background: "white" }}>
                개인정보에 대한 접근 통제 및 접근 권한의 제한 조치
              </span>
              <br />
              <span style={{ background: "white" }}>③</span>
              <span style={{ background: "white" }}>
                개인정보를 안전하게 저장 ・전송할 수 있는 암호화 기술의 적용
                또는 이에 상응하는 조치
              </span>
              <br />
              <span style={{ background: "white" }}>④</span>
              <span style={{ background: "white" }}>
                개인정보 침해사고 발생에 대응하기 위한 접속기록의 보관 및 위
                ・변조 방지 조치
              </span>
              <br />
              <span style={{ background: "white" }}>⑤</span>
              <span style={{ background: "white" }}>
                개인정보에 대한 보안프로그램의 설치 및 갱신
              </span>
              <br />
              <span style={{ background: "white" }}>⑥</span>
              <span style={{ background: "white" }}>
                개인정보의 안전한 보관을 위한 보관시설의 마련 또는 잠금 장치의
                설치
              </span>
            </p>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>제 7 조 (비밀유지)</span>
              </strong>
            </p>
            <ol>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 계약기간은 물론 계약 종료 후에도 위탁업무 수행 목적
                  범위를 넘어 개인정보를 이용하거나 이를 제3자에게 제공 또는
                  누설하여서는 안 된다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 계약이 해지되거나 또는 별도로 협의한 계약기간이
                  만료된 경우 위탁업무와 관련하여 보유하고 있는 개인정보를 즉시
                  파기하거나 "위탁자"에게 반납하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  제2항에 따라 "수탁자"가 개인정보를 파기한 경우 지체없이
                  "위탁자"에게 그 결과를 통보하여야 한다.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ background: "white" }}> </span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>
                  제 8 조 (수탁자에 대한 관리 ・감독 등)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span style={{ background: "white" }}>
                  "위탁자"는 "수탁자"에 대하여 다음 각 호의 사항을 기록
                  ・관리하도록 요구할 수 있으며, "수탁자"는 특별한 사유가 없는
                  한 이에 응하여야 한다.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ background: "white" }}>①</span>
              <span style={{ background: "white" }}>개인정보의 처리 현황</span>
              <br />
              <span style={{ background: "white" }}>②</span>
              <span style={{ background: "white" }}>
                개인정보의 접근 또는 접속현황
              </span>
              <br />
              <span style={{ background: "white" }}>③</span>
              <span style={{ background: "white" }}>
                개인정보 접근 또는 접속 대상자
              </span>
              <br />
              <span style={{ background: "white" }}>④</span>
              <span style={{ background: "white" }}>목적 외 이용 ・</span>
              <span style={{ background: "white" }}>제공</span>
              <span style={{ background: "white" }}>
                {" "}
                및 재위탁 금지 준수여부
              </span>
              <br />
              <span style={{ background: "white" }}>⑤</span>
              <span style={{ background: "white" }}>
                암호화 등 안전성 확보조치 이행여부
              </span>
              <br />
              <span style={{ background: "white" }}>⑥</span>
              <span style={{ background: "white" }}>
                그 밖에 개인정보의 보호를 위하여 필요한 사항 및 위탁자가
                수탁자에게 요구하는 사항
              </span>
            </p>
            <ol start={2}>
              <li>
                <span style={{ background: "white" }}>
                  "위탁자"는 제1항 각 호의 사항에 대한 "수탁자"의 실태를
                  점검하기 위해 관련자료의 제출 및 설명을 요구할 수 있고,
                  필요하다고 판단되는 사항에 대하여 "수탁자"에게 시정을 요구할
                  수 있다. 이 경우, "수탁자"는 "위탁자"의 요구를 거부할 합리적인
                  사유가 없는 한 위 요구에 응하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "위탁자"는 처리위탁으로 인하여 정보주체의 개인정보가 분실
                  ・도난 ・유출 ・변조 또는 훼손되지 아니하도록 1년에 2회
                  "수탁자"의 담당 직원을 교육할 수 있으며, "수탁자"는 이에
                  응하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  제1항에 따른 교육의 시기와 방법 등에 대해서는 "위탁자"와
                  "수탁자"간 협의하여 시행한다.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>
                  제 9 조(개인정보의 제공 및 파기)
                </span>
              </strong>
            </p>
            <ol>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 "위탁자"가 제공하는 개인정보에 대해 제공받는 자의
                  이용목적, 제공항목, 이용 및 보유기간("필요 최소한"으로
                  설정)등을 구체적으로 명시하여 제공하며, "수탁자"는 제공받은
                  본건 개인정보에 대해 이용 및 보유기간 초과 시 해당 정보를
                  파기하거나 상대방에게 반환하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 파기계획(파기절차 등 포함)을 수립하고, 본건
                  개인정보를 파기할 때에는 파기절차를 준수하며, 정보가 복구 또는
                  재생되지 아니하도록 조치하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"는 정보주체의 본건 개인정보를 파기한 경우
                  개인정보보호책임자는 1개월 이내에 그 처리결과를 서면으로
                  통보하여야 한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  양 당사자는 파산, 청산, 합병 등의 경우 보유한 개인정보를 즉시
                  파기하거나 개인정보보호 관련 법령에 따라 이전해야 하며
                  파산관재인, 청산인, 합병된 자의 대표 등이 파기확인서를 발행할
                  의무를 승계하도록 조치하여야 한다.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ background: "white" }}>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ background: "white" }}>제 10 조 (손해배상)</span>
              </strong>
            </p>
            <ol>
              <li>
                <span style={{ background: "white" }}>
                  "수탁자"또는 "수탁자"의 임직원, 기타 수탁자로부터 업무를
                  재위탁받은 자가 본 약정에 의하여 위탁 또는 재위탁 받은 업무를
                  수행함에 있어 이 계약에 따른 의무 및 개인정보보호법, 동법
                  시행령 등 관계법령을 위반하거나, "수탁자" 또는 "수탁자"의
                  임직원, 기타 수탁자로부터 업무를 재위탁받은 자의 귀책사유로
                  인하여 이 계약이 해지되어 "위탁자" 또는 개인정보주체 기타
                  제3자에게 손해가 발생한 경우 "수탁자"는 그 손해를 배상하여야
                  한다.
                </span>
              </li>
              <li>
                <span style={{ background: "white" }}>
                  제1항과 관련하여 개인정보주체 기타 제3자에게 발생한 손해에
                  대하여 "위탁자"가 전부 또는 일부를 배상한 때에는 "위탁자"는
                  이를 "수탁자"에게 구상할 수 있다.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms2;
