/** @jsxImportSource @emotion/react */
import { Content6Style } from "./styled";

const Content6 = () => {
  return (
    <div className="content_manager6" css={Content6Style}>
      <div className="container2">
        <div className="content_main_text2">
          <h3>커스텀 게임</h3>
          <p>
            커스텀 게임으로 자사의 브랜드 로고나 상품을
            <br />
            게임에 등장시켜 이벤트 효과를 극대화 해보세요
            <br />
            원하는 이미지를 지정된 형식에 업로드하는 것 만으로도
            <br />
            쉽게 나만의 게임을 커스텀할 수 있습니다.
          </p>
        </div>
        <div className="content_manager6_img">
          <img src={"images/manager/manager_img_5.png"} alt="content6 이미지" />
        </div>
      </div>
    </div>
  );
};

export default Content6;
