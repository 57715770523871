import Header from "../components/Header";
import Footer from "../components/Footer";

import { Main, Contents } from "../components/UseCase";
import useIndexStore from "../components/Store/store";
const UseCasePage = () => {
  const activeIndex = useIndexStore((state) => state.index);
  const setActiveIndex = useIndexStore((state) => state.indexSetting);
  return (
    <>
      <Header />
      <Main id={activeIndex} onChangeActive={setActiveIndex} />
      <Contents id={activeIndex} />
      <Footer />
    </>
  );
};

export default UseCasePage;
