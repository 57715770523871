import { css } from "@emotion/react";

export const Content5Style = css`
  height: 540px;

  .content_main_text2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    span {
      margin-top: 10px;
      img {
        margin-left: 9px;
        vertical-align: baseline;
        margin-top: 4px;
      }
    }
  }

  .content_manager5_list {
    left: 590px;
    // left: 700px;
    width: 700px;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 0 20px 0 !important;
    position: absolute !important;
    /* padding-right: 125px !important; */
    padding-left: 64px !important;

    .content_manager5_list_container:first-of-type {
      margin-left: 0;
    }
    .content_manager5_list_container {
      width: 150px;
      padding: 36px 0px;
      border-radius: 5px;
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      // margin-left: 18px; 왜 이거하면 쭉 늘어나는지??
      transition: 0.3s;
      cursor: pointer;
      .content_manager5_list_img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        background-color: #f1f1f1;
        img {
          width: 100%;
        }
      }
      .content_manager5_list_text {
        h4 {
          font-size: 12px;
          margin-top: 18px;
          color: #3887ff;
          font-weight: bold;
        }
      }
      .content_manager5_list_button {
        width: 68px;
        height: 20px;
        border: 1px solid rgba(63, 68, 96, 0.4);
        color: rgba(63, 68, 96, 0.4);
        text-decoration: none;
        display: inline-block;
        border-radius: 12px;
        font-size: 10px;
        margin-top: 10px;
        transition: 0.3s;
        line-height: 20px;
      }
    }

    .content_manager5_list_container:hover {
      border: 2px solid #3887ff;
      margin-top: -5px;
      box-shadow: 9px 9px 20px 0 rgba(0, 0, 0, 0.1);
      .content_manager5_list_button {
        border: 1px solid #3887ff;
        color: #3887ff;
      }
    }
  }
  .swiper-button-next {
    right: 23px;
    width: calc(var(--swiper-navigation-size) / 44 * 22);
  }
  .swiper-button-next:after {
    content: "";
    background: url(images/manager/game_arrow_r.png) no-repeat 0px 0px;
    width: 45px;
    position: absolute;
    left: 0px;
    top: 0;
    height: 100%;
    z-index: 5;
    background-size: contain;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .swiper-button-prev {
    width: calc(var(--swiper-navigation-size) / 44 * 36);
  }
  .swiper-button-prev:after {
    content: "";
    background: url(images/manager/game_arrow_l.png) no-repeat 75px 0px;
    width: 120px;
    position: absolute;
    right: 0px;
    top: 0;
    height: 100%;
    z-index: 5;
    background-size: contain;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @media screen and (max-width: 600px) {
    padding: 45px 0px;
    text-align: center;
    height: auto;

    .content_main_text2 {
      position: static;
      top: 0;
      transform: translateY(0);
      h3 {
        font-size: 19px;
      }
      p {
        font-size: 12px;
        line-height: 1.5;
        margin-top: 15px;
      }
      span {
        font-size: 12px;
        .c_display_inline {
          display: none;
        }
        img {
          display: none;
        }
      }
    }
    .content_manager5_list {
      position: relative !important;
      top: 0;
      transform: translateY(0);
      left: 0;
      padding-left: 0;
      padding-right: 0;
      margin: 18px 0 0 0 !important;
      max-width: 100% !important;
      padding: 5px 45px 5px 45px !important;

      .content_manager5_list_container {
        width: 100px;
        height: 145px;
        padding: 24px 8px;
        margin-left: 10px;
        .content_manager5_list_img {
          width: 45px;
          height: 45px;
        }
        .content_manager5_list_text {
          h4 {
            margin-top: 10px;
            font-size: 9px;
          }
        }
        .content_manager5_list_button {
          width: 44px;
          height: 15px;
          font-size: 8px;
          line-height: 15px;
        }
      }
    }

    .button-next {
      width: 35px;
    }
    .button-next img,
    .button-prev img {
      left: 0;
      width: 100%;
    }
    .button-prev {
      width: 35px;
    }
    .swiper-button-prev {
    }
    /* .swiper-button-next:after {
      background: url(images/manager/game_arrow_r.png) no-repeat -10px 0px;
    } */
  }
`;
