import { css } from "@emotion/react";

export const HeaderStyle = css`
  height: 120px;
  z-index: 300;
  position: absolute;
  width: 100%;
  .m_display {
    display: none;
  }
  .header_logo {
    float: left;
    height: 115px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }

  ul {
    display: flex;
    align-items: center;
    float: right;
    height: 120px;
    margin: 0; //안잡으면 왠지모르겠지만 상하16px마진생김
    padding: 0;
    li {
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      cursor: pointer;
      padding: 0 15px;
      font-weight: 500;
      a {
        color: #fff;
        text-decoration: none;
        font-size: inherit;
        font-weight: inherit;
        font-size: inherit;
        padding: 12px;
      }
    }
    .contact_link {
      padding: 12px 27px;
    }
    .nav-button {
      margin-left: 63px;
      padding: 0;
      a {
        padding: 13px 24px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 7px;
        min-width: 80px;
        display: block;
        text-align: center;
        font-size: 15px;
      }
    }
    .nav-button-line a {
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.65);
      line-height: 1;
    }
  }

  @media screen and (max-width: 600px) {
    height: 54px;
    .m_display {
      display: block;
    }
    .container {
      .header_logo {
        height: 54px;
        img {
          width: 94px;
        }
      }
      .header_menu {
        float: right;
        height: 54px;
        display: flex;
        align-items: center;
        position: absolute;
        width: 54px;
        right: 0;
        justify-content: center;
      }
      .bars {
        font-size: 18px;
        color: #fff;
        margin-top: 4px;
      }
      nav {
        width: 70%;
        height: 100%;
        position: fixed;
        right: -100%;
        top: 0;
        background: #fff;
        z-index: 300;
        transition: 0.5s ease-out;
      }

      .nav_list {
        height: auto;
        float: none;
        flex-direction: column;
        margin-top: 10px;
      }
      .nav_list li {
        width: 100%;
        padding: 0;
        border-bottom: 1px solid rgba(176, 176, 176, 0.25);
        color: #000;
        font-size: 14px;
        font-weight: bold;
      }
      .nav_list li.contact_link {
        padding: 15px 24px;
      }
      .nav_list li a {
        color: #000;
        padding: 0;
        width: 100%;
        height: 100%;
        display: block;
        padding: 15px 24px;
        font-weight: bold;
      }
      .nav_header_wrap {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        display: none;
      }
      header nav {
        width: 70%;
        height: 100%;
        position: fixed;
        right: -100%;
        top: 0;
        background: #fff;
        z-index: 300;
        transition: 0.5s ease-out;
      }
      .nav_header {
        padding: 24px 18px;
      }
      .nav_header_logo {
        width: 94px;
      }
      .nav_header_logo img {
        width: 100%;
      }
      .nav_header_close {
        position: absolute;
        top: 24px;
        right: 18px;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
      .nav_header_close img {
        width: 100%;
      }
    }
  }
`;
