import create from "zustand";

interface UsecaseIndex {
  index: number;
  indexSetting: (index: number) => void;
}

const useIndexStore = create<UsecaseIndex>((set) => ({
  //create 함수의 매개변수로 콜백함수를 받는데 이 콜백함수의 return객체에 state, setState를 넣는다
  index: 0,
  indexSetting: (index: number) => set({ index: index }),
}));
export default useIndexStore;
