/** @jsxImportSource @emotion/react */
import { Content2Style } from "./styled";

const Content2 = () => {
  return (
    <div className="content_manager2" css={Content2Style}>
      <div className="container">
        <div className="content_manager2_img c_display">
          <img src={"images/manager/manager_img_3.png"} alt="" />
        </div>
        <div className="content_manager2_text">
          <div className="content_manager2_text_box">
            <img
              src={"images/manager/manager_3_icon_1.png"}
              alt="content2 아이콘 이미지"
              className="m_display"
            />
            <h3>쉬운 이벤트 기획</h3>
            <p>
              단 몇번의 클릭만으로 커스텀 게임 제작부터
              <br className="c_display" />
              경품 발송 이벤트까지 생성 및 집행할 수 있습니다.
            </p>
          </div>
          <div className="content_manager2_text_box">
            <img
              src={"images/manager/manager_3_icon_2.png"}
              alt="content2 아이콘 이미지"
              className="m_display"
            />
            <h3>고객 참여 유도</h3>
            <p>
              게임의 재미 요소와 경품 추첨에 대한 기대감을 통해 고객 참여를
              유도하고
              <br className="c_display" />
              재방문 및 체류시간을 늘려 지속적으로 브랜드를 노출합니다.
            </p>
          </div>
          <div className="content_manager2_text_box">
            <img
              src={"images/manager/manager_3_icon_3.png"}
              alt="content2 아이콘 이미지"
              className="m_display"
            />
            <h3>실시간 모니터링 및 최적화</h3>
            <p>
              참여자 정보 및 이벤트 반응도를 분석하고 리포팅하여 실시간
              <br className="c_display" />
              모니터링을 통해 이벤트 최적화가 가능합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
