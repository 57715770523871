/** @jsxImportSource @emotion/react */
import { MainStyle } from "./styled";

const Manager = () => {
  return (
    <div css={MainStyle}>
      <div className="container">
        <div className="main_content">
          <div className="main_content_circle c_display">
            <img src={"images/main/bg_Oval_1_500.png"} alt="메인배경이미지" />
          </div>
          <div className="main_content_text">
            <h2>
              광고제작에 <br className="m_display" />
              많은 시간과 <br className="c_display" />
              비용으로 <br className="m_display" />
              고민이신가요?
            </h2>
            <p className="c_display">
              고객참여를 높이는 게임 이벤트와 원하는 이미지를 삽입할 수 있는
              <br />
              커스텀 게임 이벤트, 구매부터 발송까지 지원하는 경품 이벤트를
              이용해
              <br />
              Rich media 이벤트를 생성해 보세요!
            </p>
            <button
              className="btn-line c_display"
              onClick={() => window.open("https://manager.admerce.co.kr")}
            >
              시작하기
            </button>
          </div>
          <div className="main_content_img">
            <img src={"images/manager/top_img.png"} alt="메인 콘텐츠 이미지" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manager;
