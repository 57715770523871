import { css } from "@emotion/react";

export const Content5Style = css`
  padding: 143px 0 125px 0;
  background-color: #f7fafc;

  .content_main5_content {
    font-weight: bold;
    margin-top: 72px;
    display: inline-block;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0px;
    .content_main_text {
      h3 {
        font-size: 18px;
        line-height: 1.5;
      }
    }
    .content_main5_content {
      margin-top: 37px;
      ul {
        li {
          img {
            width: 100%;
          }
        }
      }
    }
  }
`;
