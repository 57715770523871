/** @jsxImportSource @emotion/react */
import { Content4Style } from "./styled";

const Content4 = () => {
  return (
    <div className="content_main4" css={Content4Style}>
      <div className="container">
        <div className="content_main4_content">
          <div className="content_main_text">
            <h3>
              누적 광고 애드머스 주요 지표를
              <br className="m_display" />
              분석하여 <br className="c_display" />
              캠페인을 추천합니다
            </h3>
          </div>
          <ul className="content_main4_content_list">
            <li>
              <h4>
                <span className="num">1,000,000+</span>
              </h4>
              <p>
                분석용 캠페인
                <br />
                결과 데이터 보유
                <span className="line"></span>
              </p>
            </li>
            <li>
              <h4>
                <span className="num">150,000+</span>
              </h4>
              <p>
                애드머스를 활용한
                <br />
                누적 켐페인 진행수
                <span className="line"></span>
              </p>
            </li>
            <li>
              <h4>
                <span className="num">13,000,000+</span>
              </h4>
              <p>
                누적 이벤트
                <br className="m_display" />
                참여 횟수
                <span className="line"></span>
              </p>
            </li>
            <li>
              <h4>
                <span className="num">5.8%</span>
              </h4>
              <p>
                평균 반응률
                <br className="m_display" />
                (CTR)
                <span className="line"></span>
              </p>
            </li>
            <li>
              <h4>
                <span className="num">3.15%</span>
              </h4>
              <p>
                평균 구매 전환율
                <span className="line"></span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Content4;
