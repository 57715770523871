import { css } from "@emotion/react";

export const Content3Style = css`
  padding: 143px 0 125px 0;
  background-color: #f7fafc;
  .content_main_text {
    button {
      color: #3b81fe;
      border: 1px solid #3b81fe;
    }
  }
  .content_main3_content {
    font-weight: bold;
    margin-top: 72px;
    display: inline-block;
    width: 100%;
    .info {
      padding-left: 46px;
      .info_num {
        font-size: 60px;
        font-weight: bold;
        color: #3b81fe;
      }
      .info_title {
        font-size: 32px;
        font-weight: bold;
        margin-top: 10px;
      }
      .info_text {
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0px;
    .content_main_text {
      h3 {
        font-size: 18px;
        line-height: 1.5;
      }
      p {
        font-size: 12px;
        line-height: 1.6;
        margin-top: 15px;
      }
      button {
        width: 90px;
        padding: 7px;
        font-size: 9px;
        border-radius: 14px;
        margin-top: 15px;
      }
    }
    .content_main3_content {
      margin-top: 37px;

      li:first-of-type .info {
        margin-top: 0;
      }
      .info {
        margin-top: 20px;
        padding-left: 0px;
        text-align: center;
        .info_num {
          font-size: 24px;
          font-weight: bold;
          color: #3b81fe;
        }
        .info_title {
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
        }
        .info_text {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }
`;
