/** @jsxImportSource @emotion/react */
import { Content3Style } from "./styled";

const Content3 = () => {
  // const Conten3Data = [
  //   {
  //     id: 1,
  //     img: "images/manager/manager_4_icon_1.png",
  //     title: "이벤트 정보 입력",

  //   },
  //   {
  //     id: 2,
  //     img: "images/manager/manager_4_icon_2.png",
  //     title: "상세페이지 작성",

  //   },
  //   {
  //     id: 3,
  //     img: "images/manager/manager_4_icon_3.png",
  //     title: "다양한 컨텐츠 선택",

  //   },{
  //     id: 4,
  //     img: "images/manager/manager_4_icon_4.png",
  //     title: "수집항목 및 요금 설정",

  //   },{
  //     id: 5,
  //     img: "images/manager/manager_4_icon_5.png",
  //     title: "완료",

  //   },
  // ];
  return (
    <div className="content_manager3" css={Content3Style}>
      <div className="container">
        <div className="content_main_text3">
          <h2>광고의 처음부터 마지막까지!</h2>
          <p>
            All-in-One 시스템으로를 통해 쉽고 간편하게
            <br className="m_display" />
            이벤트를 생성할 수 있습니다.{" "}
          </p>
        </div>
        <ul className="content_manager3_content">
          <li className="col5">
            <img src={"images/manager/manager_4_icon_1.png"} alt="" />
            <p>이벤트 정보 입력</p>
            <div className="line">
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
            </div>
          </li>

          <li className="col5">
            <img src={"images/manager/manager_4_icon_2.png"} alt="" />
            <p>상세페이지 작성</p>
            <div className="line">
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
            </div>
          </li>
          <li className="col5">
            <img src={"images/manager/manager_4_icon_3.png"} alt="" />
            <p>다양한 컨텐츠 선택</p>
            <div className="line">
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
            </div>
          </li>
          <li className="col5">
            <img src={"images/manager/manager_4_icon_4.png"} alt="" />
            <p>수집항목 및 요금 설정</p>
            <div className="line">
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
            </div>
          </li>
          <li className="col5">
            <img src={"images/manager/manager_4_icon_5.png"} alt="" />
            <p>완료</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Content3;
