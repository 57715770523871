import Header from "../components/Header";
import Footer from "../components/Footer";
import Terms2 from "../components/Terms2";

const Term2Page = () => {
  return (
    <>
      <Header />
      <Terms2 />
      <Footer />
    </>
  );
};

export default Term2Page;
