/** @jsxImportSource @emotion/react */
import { TermsStyle } from "../Terms2/styled";

const Terms1 = () => {
  return (
    <div css={TermsStyle}>
      <div className="main_content2">
        <div className="container2">
          <div className="main_content_text3">
            <h2>개인정보 처리 방침</h2>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="content_temrs">
            <p style={{ textAlign: "center" }}>
              <strong>
                <span style={{ fontSize: "11pt", lineHeight: "115%" }}>
                  개인정보처리방침
                </span>
              </strong>
            </p>
            <p style={{ textAlign: "center" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                지니웍스 주식회사의 애드머스 서비스(이하 애드머스라 함)는 고객의
                개인정보보호를 소중하게 생각하고, 고객의 개인정보를 보호하기
                위하여 항상 최선을 다해 노력하고 있습니다. 애드머스는
                개인정보보호 관련 주요 법률인 「정보통신망 이용촉진 및 정보보호
                등에 관한 법률」 을 비롯한 모든 개인정보보호 관련 법률규정을
                준수하고 있습니다. 또한, 애드머스는 「개인정보처리방침」을
                제정하여 이를 준수하고 있으며, 이를 애드머스 홈페이지(
                <span style={{ background: "yellow" }}>www.admerce.co.kr</span>,
                이하 애드머스 홈페이지라 합니다)에 공개하여 고객이 언제나
                용이하게 열람할 수 있도록 하고 있습니다. 애드머스의
                「개인정보처리방침」은 관련 법률 및 지침의 변경 또는 내부 운영
                방침의 변경에 따라 변경될 수 있습니다. 「개인정보처리방침」이
                변경될 경우 변경된 사항을 애드머스 홈페이지를 통하여 공지합니다.
                애드머스 개인정보처리방침은 아래와 같은 내용을 담고
                있습니다.&nbsp;
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  [
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  용어설명]
                </span>
              </strong>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                "
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                개인정보"라 함은 생존하고 있는 개인에 관한 정보로서
                성명&bull;주민등록번호 등에 의하여 당해 개인을 알아볼 수 있는
                부호&bull;문자&bull;음성&bull;음향&bull;영상 및 생체특성 등에
                관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는 경우에도
                다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함합니다)를
                말합니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 고객의 개인정보보호를 매우 중요시하며, 「정보통신망
                이용촉진 및 정보보호 등에 관한 법률」의 개인정보 보호 규정을
                준수하고 있습니다. 애드머스는 「개인정보처리방침」을 통하여
                고객이 제공하는 개인정보의 수집목적 및 이용목적, 개인정보보호를
                위해 애드머스가 취하고 있는 조치 등을 알려드립니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 「개인정보처리방침」을 인터넷 홈페이지 첫 화면에
                공개함으로써 고객이 언제나 용이하게 볼 수 있도록 조치하고
                있습니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 「개인정보처리방침」의 지속적인 개선을 위하여
                「개인정보처리방침」을 개정하는데 필요한 절차를 정하고 있습니다.
                그리고 「개인정보처리방침」을 개정하는 경우 버전번호 등을
                부여하여 개정된 사항을 고객이 쉽게 알아볼 수 있도록 하고
                있습니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스의 「개인정보처리방침」 중 중요한 사항이 변경되는
                경우에는 변경되는 「개인정보처리방침」을 시행하기 전에 인터넷
                홈페이지 등을 통하여 변경이유 및 내용을 고객에게 공지합니다.
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보의 수집항목 및 이용목적
                </span>
              </strong>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 개인정보의 수집 시 관련법규에 따라 이용약관에 그
                수집범위 및 목적을 사전 고지합니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                회사는 이용자가 제공한 개인정보를 당해 이용자의 동의 없이 서비스
                제공 이외의 목적으로 이용하지 아니합니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스가 수집하는 이용자의 개인정보 및 개인정보의 이용목적,
                보유기간은 다음과 같습니다.
              </span>
            </p>
            <table
              style={{
                width: "470.45pt",
                marginLeft: "7.5",
                background: "white",
                borderCollapse: "collapse",
              }}
              width="627"
            >
              <thead>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "60.3pt",
                      borderTop: "solid #494e55 1.5pt",
                      borderLeft: "none",
                      borderBottom: "solid #494e55 1.5pt",
                      borderRight: "none",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="80"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                          유형
                        </span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "93.85pt",
                      borderTop: "solid #494e55 1.5pt",
                      borderLeft: "none",
                      borderBottom: "solid #494e55 1.5pt",
                      borderRight: "none",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="125"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                          이용목적
                        </span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "142.05pt",
                      borderTop: "solid #494e55 1.5pt",
                      borderLeft: "none",
                      borderBottom: "solid #494e55 1.5pt",
                      borderRight: "none",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                          수집항목
                        </span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "163.45pt",
                      borderTop: "solid #494e55 1.5pt",
                      borderLeft: "none",
                      borderBottom: "solid #494e55 1.5pt",
                      borderRight: "none",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="218"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                          보유기간
                        </span>
                      </strong>
                    </p>
                  </td>
                  <td style={{ border: "none", padding: 0, width: "14" }}>
                    <p>&nbsp;</p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "60.3pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    rowSpan={2}
                    width="80"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>필수항목</span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "93.85pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    rowSpan={2}
                    width="125"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        회원가입 및 가입자 별 주문정보 식별
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "142.05pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        개인회원 : 성명, 아이디, 비밀번호, 발신자 전화번호,
                        수신자 휴대전화 번호
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "163.45pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    rowSpan={2}
                    width="218"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        회원 탈퇴 시 또는 유효기간* 도래 시까지&nbsp;
                        <br />
                        *유효기간 이라 함은 일정기간(1년) 서비스를 이용하지 않은
                        경우로써, 탈퇴, 분리보관 등의 조치가 적용됨(단, 아이디는
                        재가입제한을 위해 탈퇴 후 1개월간 보관)
                      </span>
                    </p>
                  </td>
                  <td style={{ border: "none", padding: 0, width: "14" }}>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "142.05pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        기업회원 : 사업자 등록번호, 상호명, 대표자명,
                        대표전화번호, 담당자성명, 아이디, 비밀번호, 업종,
                        근무부서/직책, 유선전화번호, 발신자 전화번호, 수신자
                        전화번호
                      </span>
                    </p>
                  </td>
                  <td style={{ border: "none", padding: 0, width: "14" }}>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "60.3pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="80"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>필수항목</span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "93.85pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="125"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        고객 고충 상담 및 문의 응대
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "142.05pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        아이디, 이메일주소, 휴대폰 번호
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "163.45pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="218"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        회원 탈퇴시 까지
                      </span>
                    </p>
                  </td>
                  <td style={{ border: "none", padding: 0, width: "14" }}>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "60.3pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    rowSpan={2}
                    width="80"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        필수항목
                        <br />
                        (상품구매 등 서비스 이용시 필수적인 정보)
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "93.85pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="125"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        유료 상품 결제 시, 주문자 식별을 위한 본인인증
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "142.05pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        개인회원 : 휴대폰 번호, CI
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "163.45pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="218"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        회원 탈퇴시 까지
                      </span>
                    </p>
                  </td>
                  <td style={{ border: "none", padding: 0, width: "14" }}>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "93.85pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="125"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        구매 내역 환불 처리
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "142.05pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        예금주명, 생년월일(사업자등록번호), 환불계좌 은행명,
                        환불 계좌번호
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "163.45pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="218"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        회원 탈퇴시 까지
                      </span>
                    </p>
                  </td>
                  <td style={{ border: "none", padding: 0, width: "14" }}>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr style={{ height: "37.6pt" }}>
                  <td
                    style={{
                      width: "60.3pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="80"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>선택항목</span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "93.85pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="125"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        이벤트 등 프로모션 알림 메일/ SMS 발송
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "142.05pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    width="189"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>SMS/</span>
                      <span style={{ fontSize: "10.5pt" }}>
                        이메일 수신여부
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "174.25pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "37.6pt",
                    }}
                    colSpan={2}
                    width="232"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        회원 탈퇴시 까지
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                marginBottom: "0.0001pt",
                lineHeight: "normal",

                wordBreak: "keep-all",
              }}
            >
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                회사는 이용자에게 최적화된 서비스를 제공하고, 신상품이나 이벤트
                정보 안내, 설문조사 등 이용자 지향적인 마케팅을 수행하기 위하여
                이용자가 제공한 정보 및 서비스 이용과 관련한 정보를 활용할 수
                있습니다.
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.0001pt",
                lineHeight: "normal",

                wordBreak: "keep-all",
              }}
            >
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.0001pt",
                lineHeight: "normal",

                wordBreak: "keep-all",
              }}
            >
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보의 수집방법
                </span>
              </strong>
            </p>
            <p
              style={{
                marginBottom: "0.0001pt",
                lineHeight: "normal",

                wordBreak: "keep-all",
              }}
            >
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 애드머스에서 운영하는 홈페이지, 상담게시판,
                전화/팩스를 통한 증빙 수집 및 본인/대리인 확인, 경품행사 응모
                등으로 개인정보를 수집합니다.
              </span>
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                수집한 개인정보의 이용 및 제 3자 제공
              </span>
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                회사는 고객님의 개인정보를 동의 받은 범위 내에서 사용하며, 해당
                범위를 초과하여 이용하거나 타인 또는 다른 기업과 기관에 제공하지
                않습니다.&nbsp;
              </span>
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                단, 다음의 경우에는 주의를 기울여 개인정보를 이용&bull;제공 할
                수 있습니다.&nbsp;
              </span>
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                가. 고객님의 동의를 사전에 얻은 경우&nbsp;
              </span>
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                나. 법령에 정해진 의무 또는 수사목적으로 법령에서 정해진 절차와
                방법에 따라 수사기관의 정당한 요청이 있는 경우
              </span>
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                다. 회사는 통계작성, 학술연구 또는 시장조사를 위하여 필요하다면
                이용자가 제공한 개인정보를 식별할 수 없는 형태로 가공하여 제
                3자에게 제공할 수 있습니다.
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.0001pt",
                lineHeight: "normal",
                wordBreak: "keep-all",
              }}
            >
              <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                <br />
              </span>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보 취급업무의 위탁
                </span>
              </strong>
            </p>
            <ol>
              <li
                style={{
                  marginBottom: "0.0001pt",
                  lineHeight: "normal",
                  wordBreak: "keep-all",
                }}
              >
                <span
                  style={{
                    fontSize: "10.5pt",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스는 보다 나은 서비스 제공, 고객편의 제공 등 원활한 업무
                  수행을 위하여 다음과 같이 개인정보 취급 업무를 외부 전문업체에
                  위탁하여 운영하고 있습니다.
                </span>
                <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                  <br />
                  <br />
                </span>
              </li>
            </ol>
            <table
              style={{
                width: "456.35pt",
                background: "white",
                borderCollapse: "collapse",
              }}
              width="608"
            >
              <thead>
                <tr style={{ height: "40.5pt" }}>
                  <td
                    style={{
                      width: "161.55pt",
                      borderTop: "solid #494e55 1.5pt",
                      borderLeft: "none",
                      borderBottom: "solid #494e55 1.5pt",
                      borderRight: "none",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="215"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                          수탁자
                        </span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "294.8pt",
                      borderTop: "solid #494e55 1.5pt",
                      borderLeft: "none",
                      borderBottom: "solid #494e55 1.5pt",
                      borderRight: "none",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="393"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: "10.5pt", color: "#222222" }}>
                          취급위탁업무 내용
                        </span>
                      </strong>
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "40.5pt" }}>
                  <td
                    style={{
                      width: "161.55pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="215"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>KG</span>
                      <span style={{ fontSize: "10.5pt" }}>올앳</span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "294.8pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="393"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt" }}>
                        카드결제 및 실시간 계좌이체 대행
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "40.5pt" }}>
                  <td
                    style={{
                      width: "161.55pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="215"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10.5pt",
                          color: "red",
                          background: "yellow",
                        }}
                      >
                        다우기술㈜
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "294.8pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="393"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10.5pt",
                          color: "red",
                          background: "yellow",
                        }}
                      >
                        문자메시지 발송 대행
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "40.5pt" }}>
                  <td
                    style={{
                      width: "161.55pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="215"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt", color: "red" }}>
                        나이스평가정보
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "294.8pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="393"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt", color: "red" }}>
                        기업인증 대행 기관
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "40.5pt" }}>
                  <td
                    style={{
                      width: "161.55pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="215"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt", color: "red" }}>
                        금융결제원
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "294.8pt",
                      border: "none",
                      borderBottom: "solid #e6e6e6 1pt",
                      padding: "0cm 7.5pt 0cm 7.5pt",
                      height: "40.5pt",
                    }}
                    width="393"
                  >
                    <p
                      style={{
                        marginBottom: "0.0001pt",
                        lineHeight: "normal",
                      }}
                    >
                      <span style={{ fontSize: "10.5pt", color: "red" }}>
                        애드머스 환불처리
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <ol start={2}>
              <li>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스는 위탁업무계약서 등을 통하여 개인정보보호 관련 법규의
                  준수, 개인정보에 관한 비밀유지, 제3자 제공에 대한 금지,
                  사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환
                  또는 파기 의무 등을 규정하고, 수탁자가 이를 준수하도록
                  관리하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    개인정보의 보관 및 이용기간
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  고객의 개인정보는 애드머스가 고객에게 서비스를 제공하는 기간
                  동안에 한하여 보유 및 이용하고, 회원에서 탈퇴하거나 개인정보
                  이용목적을 달성한 경우 또는 일정기간 서비스를 이용하지 않아
                  개인정보의 유효기간이 도래하는 경우 수집된 개인정보를
                  열람하거나 이용 할 수 없도록 파기처리 합니다. 다만,
                  국세기본법, 법인세법, 부가가치세법 기타 관계법령의 규정에
                  의하여 보존할 필요성이 있는 경우에는 관계법령에 따라
                  보존합니다
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>1. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  계약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자
                  보호에 관한 법률)
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>2. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  대금결제 및 재화 등의 공급에 관한 기록 : 5년
                  (전자상거래등에서의 소비자 보호에 관한 법률)
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>3. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  회원의 불만 또는 분쟁처리에 관한 기록 : 3년
                  (전자상거래등에서의 소비자 보호에 관한 법률)
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>4. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  거래에 관한 장부 및 증빙서류 : 5년 (국세기본법, 법인세법,
                  부가가치세법 등)
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  ※ 개인정보의 유효기간이라 함은? 고객님께서 최근 본 서비스를
                  이용하신 이래 일정기간(1년) 동안 주문 이력이 없으신 경우,
                  고객님 개인정보의 안전한 보호와 이로 인한 피해발생을 방지하기
                  위해 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에
                  정해진 바에 따라 개인정보 분리 보관 및 파기 등 안전한 별도의
                  조치를 적용하는 것을 말합니다.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    개인정보의 파기절차 및 방법
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스는 수집한 개인정보의 이용목적이 달성된 후에는 보관 및
                  이용기간에 따라 해당 정보를 지체 없이 파기합니다. 파기절차 및
                  방법은 다음과 같습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  가. 파기절차 : 고객이 서비스 이용 등을 위해 기재한 개인정보는
                  서비스 해지 등 이용목적이 달성된 후 내부 방침 및 기타 관련
                  법령에 의한 정보보호 사유에 따라(보관기간 및 이용기간 참조)
                  일정기간 동안 저장된 후 삭제되거나 파기합니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  나. 파기방법 : 서면양식에 기재하거나, 종이에 출력된 개인정보는
                  분쇄기로 분쇄 또는 소각하거나 화학약품 처리를 하여 용해하여
                  파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
                  없는 기술적 방법을 사용하여 삭제합니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    이용자 및 법정대리인의 권리와 그 행사방법
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>1. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  고객은 언제든지 등록되어 있는 고객의 개인정보를 열람하거나
                  오류 또는 변경사항에 대하여 정정을 요청하실 수 있습니다.
                  개인정보를 열람하고자 할 경우에는 「마이페이지」를 클릭하여
                  직접 열람하실 수 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>2. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  고객은 회사가 보관하는 개인정보의 삭제 또는 이용 중지를
                  요청하실 수 있습니다. 단 다른 법률에 의해 보관의 의무가 있는
                  정보는 법정 기간만큼 보관할 수 있으며, 삭제 및 이용 중지 요청
                  시에는 애드머스 서비스를 이용하실 수 없습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                  <span style={{ background: "white" }}>&lt;</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  열람청구 방법 및 열람 청구 가능 항목&gt;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  ① 애드머스 웹 사이트 내 1:1 문의를 활용하는 방법
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>-</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  웹 사이트 : 홈페이지 로그인 &gt; Contact &gt; 요청 내역 입력
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  ②아래 5가지 항목에 대한 열람 청구 요청 가능
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>-</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보의 항목 및 내용
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>-</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보 수집,이용의 목적
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>-</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보 보유 및 이용기간
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>-</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보의 제3자 제공 현황
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>-</span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보 처리에 동의한 사실 및 내용
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    아동의 개인정보보호
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스는 만 14세 미만 아동(이하 '아동')의 개인정보를
                  수집.이용하지 않습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    개인정보의 기술적, 관리적 대책
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>1. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  기술적 대책
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  가. 애드머스는 고객의 개인정보를 취급함에 있어 개인정보가
                  분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를
                  위하여 아래와 같은 기술적 대책을 적용하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  가. 고객의 개인정보는 외부망에서 접근 및 침입이 불가능한
                  내부망을 활용하여 관리되고 있으며, 파일 및 전송데이터를
                  암호화하거나 파일 잠금기능 (Lock)을 사용하여 중요한 데이터는
                  별도의 보안기능을 통해 철저하게 보호되고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  나. 애드머스는 개인정보처리 시스템 및 개인정보취급자가
                  개인정보 처리에 이용하는 정보기기에 컴퓨터바이러스, 스파이
                  웨어 등 악성 프로그램의 침투 여부를 항시 점검ㆍ처리할 수
                  있도록 백신프로그램을 설치하여 개인정보 침해를 방지하기 위한
                  조치를 취하고 있습니다. 또한, 백신프로그램은 주기적으로
                  업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는
                  즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고
                  있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  다. 애드머스는 암호알고리즘 등의 이용을 통하여 네트워크상에서
                  개인정보를 안전하게 전송할 수 있는 보안장치를 채택 하고
                  있습니다.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  라. 해킹 등 외부침입에 대비하여 침입차단시스템 및
                  침입탐지시스템을 운영하여 사내 네트워크를 보호하고, 각
                  서버마다 접근제어시스템을 설치하여 보안을 강화하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>2. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  관리적 대책
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  가. 애드머스는 개인정보의 안전한 보호를 위하여 주요 시스템 및
                  설비에 대하여 외부 전문기관으로부터 정보보호관리체계 인증 등
                  객관적인 인증을 받아 운영되고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  나. 애드머스는 고객의 개인정보에 대한 접근 및 관리에 필요한
                  절차 등을 마련하여 소속 직원으로 하여금 이를 숙지하고
                  준수하도록 하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  다. 애드머스는 고객의 개인정보를 취급할 수 있는 자를
                  최소한으로 제한하고 있습니다. 고객의 개인정보를 취급할 수 있는
                  자는 아래와 같습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  고객을 직/간접적으로 상대로 하여 마케팅 업무를 수행하는 자
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보보호책임자 및 개인정보보호담당자 등
                  개인정보관리/보호업무를 수행하는 자
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  기타 업무상 개인정보의 취급이 불가피한 자
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  라. 애드머스는 컴퓨터를 이용하여 고객의 개인정보를 처리하는
                  경우 개인정보에 대한 접근권한을 가진 담당자를 지정하여
                  식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
                  정기적으로 갱신하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  마. 개인정보를 취급하는 직원을 대상으로 새로운 보안기술 습득
                  및 개인정보보호 의무 등에 관해 정기적인 사내 교육 및 외부
                  위탁교육을 실시하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  바. 신규직원 채용 시 정보보호서약서 또는 개인정보보호서약서에
                  서명함으로 직원에 의한 정보유출을 사전에 방지하고
                  개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기
                  위한 내부절차를 마련하여 지속적으로 시행하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  사.직원 퇴직 시 비밀유지서약서에 서명함으로 고객의 개인정보를
                  취급하였던 자가 직무상 알게 된 개인정보를 훼손/침해 또는
                  누설하지 않도록 하고 있습니다.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  아. 개인정보 취급자의 업무 인수인계는 보안이 유지된 상태에서
                  철저하게 이뤄지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에
                  대한 책임을 명확하게 규정하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  자. 전산실 및 자료보관실 등을 특별 보호구역으로 설정하여 출입
                  통제 등 출입관리절차를 시행하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  차. 서비스이용계약체결 또는 서비스제공을 위하여 고객의
                  신용카드번호, 은행결제계좌 등 대금결재에 관한 정보를
                  수집하거나 고객에게 제공하는 경우 당해 고객이 본인임을
                  확인하기 위하여 필요한 조치를 취하고 있습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    링크사이트
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>1. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스는 애드머스 홈페이지를 통하여 고객에게 다른 회사의
                  웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우
                  애드머스는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로
                  그로부터 제공받는 서비스나 자료의 유용성에 대해 책임을
                  부담하거나 보증할 수 없습니다.
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <span style={{ background: "white" }}>2. </span>
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스 홈페이지에 포함되어 있는 링크를 클릭(click)하여 타
                  사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의
                  개인정보처리방침은 애드머스의 「개인정보처리방침」과
                  무관하므로 새로 방문한 사이트의 방침을 숙지하시기
                  바랍니다.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
                <strong>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "115%",
                      color: "#222222",
                      background: "white",
                    }}
                  >
                    개인정보의 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  애드머스는 개인정보 수집을 위한 자동 수집 장치를 설치/운영하지
                  않습니다.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                  }}
                >
                  <br />
                  <br />
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  의견수렴 및 불만 처리
                </span>
              </strong>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>1. </span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 고객의 의견을 가장 소중하게 생각합니다. 애드머스에
                대하여 문의사항이 있을 경우 고객센터 등에 문의하시면 신속/정확한
                답변을 드리겠습니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>2. </span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 고객과의 원활한 의사소통을 위해 고객상담창구를
                운영하고 있으며, 고객센터의 연락처는 다음과 같습니다.&nbsp;
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                [
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                고객센터]
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                전화번호 : 1800-1011
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                전자우편 :
                <span style={{ background: "yellow" }}>www.admerce.co.kr</span>
                에 접속하여 고객센터&gt; Contact에 글을 접수하실 수 있습니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>3. </span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                기타 개인정보 침해에 관한 상담이 필요한 경우에는
                한국정보보호진흥원 개인정보침해신고센터, 경찰청
                사이버테러대응센터 등으로 문의하실 수 있습니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>[</span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                개인정보 침해신고센터]
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                전화 : (국번없이)118
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>
                  URL : http://privacy.kisa.or.kr
                </span>
                <br />
                <span style={{ background: "white" }}>[</span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                대검찰청 사이버범죄수사단]
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                전화 : 02-3480-3571
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                전자우편 : cybercid@spo.go.kr
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>[</span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                경찰청 사이버테러대응센터]
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                전화 : 1566-0112
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>
                  URL : http://www.netan.go.kr
                </span>
                <br />
                <br />
              </span>
              <strong>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    color: "#222222",
                    background: "white",
                  }}
                >
                  개인정보보호책임자 및 담당자
                </span>
              </strong>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>1. </span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                애드머스는 고객의 개인정보보호를 가장 중요시하며, 고객의
                개인정보가 훼손, 침해 또는 누설되지 않도록 최선을 다하고
                있습니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등
                기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로
                인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에
                관해서는 책임이 없습니다.
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                <br />
                <span style={{ background: "white" }}>2. </span>
              </span>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                  background: "white",
                }}
              >
                고객의 개인정보 보호 관련 문의 시 고객센터에서 신속하고 성실하게
                답변을 드리도록 하고 있습니다. 또한 고객이 애드머스의 총괄부서
                개인정보보호담당자와 연락을 원하실 경우 아래의 연락처 또는
                이메일로 연락을 주시면 개인정보 관련 문의사항에 대하여 신속하고
                성실하게 답변해드리겠습니다.
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "10.5pt",
                  lineHeight: "115%",
                  color: "#222222",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p style={{ lineHeight: "normal" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                개인정보 보호책임자 및 개인정보 보호 업무 담당부서
              </span>
            </p>
            <p style={{ lineHeight: "normal" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                　개인정보 보호 책임자 : 이승미
              </span>
            </p>
            <p style={{ lineHeight: "normal" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                　전화 : 1800-1011
              </span>
            </p>
            <p style={{ textIndent: "10.5pt", lineHeight: "normal" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                문의처 :{" "}
              </span>
              <span style={{ fontSize: "10.5pt", background: "white" }}>
                <a href="mailto:contact@genieworks.net">
                  contact@genieworks.net
                </a>
              </span>
            </p>
            <p style={{ lineHeight: "normal" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p style={{ lineHeight: "normal" }}>
              <span
                style={{
                  fontSize: "10.5pt",
                  color: "#222222",
                  background: "white",
                }}
              >
                개인 정보 처리 방침 시행일자: 2019년 1월 8일
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms1;
