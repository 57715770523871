import { css } from "@emotion/react";

export const MainStyle = css`
  width: 100%;
  height: 720px;
  background-image: linear-gradient(116deg, #00a9f9, #6f6eff);
  position: relative;
  padding-top: 120px;
  .container {
    padding: 0 24px !important;
  }

  .main_content3 {
    padding-top: 75px;
    padding-bottom: 75px;

    .main_content_text2 {
      h2 {
        text-align: center;
        font-size: 42px;
        color: #fff;
        margin-top: 30px;
        font-weight: bold;
      }
    }
    .swiper {
      margin-top: 40px;
      padding-bottom: 20px;

      .swiper-slide {
        width: 570px;
        opacity: 0.5;
        transition: opacity, transform 0.3s;
        transform: scale(0.95);
        img {
          width: 100%;
        }
      }

      .swiper-slide-active {
        opacity: 1 !important;
        transform: scale(1);
      }
    }
    .swiper-container {
      margin: 40px 0 auto;
      position: relative;
      overflow: hidden;
      list-style: none;
      padding: 0;
    }
  }
  .swiper-pagination-bullets {
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    // width: 10px;
    // height: 10px;
  }
  .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }

  @media screen and (max-width: 600px) {
    height: auto;
    padding-right: 0px !important;
    padding-top: 54px;
    .main_content3 {
      .swiper {
        .swiper-slide {
          width: 290px;
          img {
            width: 100%;
          }
        }
      }
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }
    .css-5lwg92-Header .container {
      padding-left: 24px;
    }
    .main_content3 {
      height: auto;
      padding-top: 27px;
      padding-bottom: 20px;
      overflow: hidden;
      .main_content_text2 {
        h2 {
          font-size: 22px;
        }
      }
    }
  }
`;
