/** @jsxImportSource @emotion/react */
import { Content9Style } from "./styled";

const Content9 = () => {
  return (
    <div className="content_manager9" css={Content9Style}>
      <div className="container">
        <div className="content_manager9_circle">
          <img src={"images/main/bg_Oval_1.png"} alt="" />
        </div>
        <div className="content_manager9_circle2">
          <img src={"images/main/bg_Oval_1_500.png"} alt="" />
        </div>
      </div>
      <div className="content_main_text3">
        <h2>실시간으로 이벤트 진행 관리 및 효과 분석</h2>
        <p>실시간 모니터링으로 광고 성능을 최적화 해보세요.</p>
        <button
          className="btn-line c_display"
          onClick={() => window.open("https://manager.admerce.co.kr")}
        >
          바로가기
        </button>
      </div>
      <div className="content_manager9_img">
        <img src={"images/manager/manager_img_4.png"} alt="content9 이미지" />
      </div>
    </div>
  );
};

export default Content9;
