import { css } from "@emotion/react";

export const Content2Style = css`
  padding: 154px 0 500px 0;
  background-image: linear-gradient(138deg, #5f41d6, #26fff0 90%, #47f2ff);
  position: relative;
  overflow: hidden;
  .content_main_text {
    h2 {
      color: #fff;
    }
    p {
      color: #fff !important;
    }
  }
  .content_main2_circle {
    position: absolute;
    top: -284px;
    left: 43%;
  }
  .content_main2_img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      float: left;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 60px 0px 190px 0;
    .c_display {
      display: none;
    }
    .content_main_text {
      h2 {
        font-size: 19px;
      }
      p {
        font-size: 12px;
        line-height: 1.6;
        margin-top: 15px;
      }
      button {
        width: 90px;
        padding: 7px;
        font-size: 9px;
        border-radius: 14px px;
        margin-top: 15px;
      }
    }
    .content_main2_img img {
      width: 325px;
    }
  }
`;
