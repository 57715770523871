import { css } from "@emotion/react";

export const Content7Style = css`
  height: 540px;
  .content_main_text2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
  }
  .content_manager7_img {
    left: 60px;
    bottom: 0;
    position: absolute;
    img {
      float: left;
    }
  }
  @media screen and (max-width: 600px) {
    height: auto;
    text-align: center;
    padding-top: 50px;
    .content_main_text2 {
      position: static;
      top: 0;
      transform: translate(0);
      h3 {
        font-size: 19px;
      }
      p {
        font-size: 12px;
        line-height: 1.5;
        margin-top: 15px;
      }
    }
    .content_manager7_img {
      position: static;
      width: 100%;
      margin-top: 20px;
      img {
        width: 100%;
        max-width: 312px;
        float: none;
      }
    }
  }
`;
