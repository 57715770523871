import { css } from "@emotion/react";

export const TermsStyle = css`
  .main_content2 {
    padding: 120px 0 500px 0;
    width: 100%;
    height: 720px;
    background-image: linear-gradient(116deg, #00a9f9, #6f6eff);

    .container2 {
      width: 1080px;
      margin: 0 auto;
      position: relative;
      height: 100%;
      padding: 0 60px;
      .main_content_text3 {
        h2 {
          text-align: center;
          font-size: 45px;
          font-weight: bold;
          color: #fff;
          margin-top: 127px;
        }
      }
    }
  }
  .content {
    .content_temrs {
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      overflow: hidden;
      top: -230px;
      position: relative;
      z-index: 10;
      border: 2px solid #f1f1f4;
      box-sizing: border-box;
      height: 1200px;
      min-height: 800px;
      overflow-y: auto;
      padding: 40px 30px;
      font-size: 16px;
      line-height: 1.6;
    }
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 900px;
    .main_content2 {
      padding: 0;
      height: 360px;

      .container2 {
        width: 100%;
        padding: 0 30px;
        .main_content_text3 {
          h2 {
            font-size: 23px;
          }
        }
      }
    }
    .content {
      .container {
        .content_temrs {
          position: absolute;
          top: 230px;
          width: 85%;
          height: 900px;
          min-height: 500px;
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
`;
