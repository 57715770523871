/** @jsxImportSource @emotion/react */
import { Content1Style } from "./styled";

const Content1 = () => {
  return (
    <div className="content_manager1" css={Content1Style}>
      <div className="container">
        <div className="content_manager_img">
          <img src={"images/manager/manager_img.png"} alt="content1 이미지" />
        </div>
        <div className="content_manager_text">
          <h3>광고 집행이 어려우신가요?</h3>
          <h4>
            쉽고 간편한 인터랙티브 이벤트를
            <br className="m_display" />
            진행하세요!
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Content1;
