/** @jsxImportSource @emotion/react */
import { ModalStyle } from "./styled";

import ReactModal from "react-modal";

const Modal = ({ isOpenModal, onClose }: any) => {
  const handleClickCancel = () => {
    onClose();
  };
  return (
    <ReactModal
      isOpen={isOpenModal}
      css={ModalStyle}
      style={{
        overlay: {
          zIndex: 1000,
        },
      }}
    >
      <div className="contact">
        <div className="contact_wrap">
          <div className="contact_header">
            <div className="contact_header_logo">
              <img src={"/images/common/bi3.png"} alt="" />
            </div>
            <div className="contact_header_close" onClick={handleClickCancel}>
              <img src={"/images/common/close.png"} alt="" />
            </div>
          </div>
          <div className="contact_content">
            <div className="container3">
              <div className="contact_content_text">
                <h2>애드머스 문의하기</h2>
                <p className="c_display">
                  애드머스와 관련한 어떤 것이라도 문의해주세요.
                  <br />
                  애드머스가 당신의 비지니스가 성공할 수 있도록 함께합니다.
                </p>
              </div>
              <div className="content_contact_sub">
                <div
                  className="content_contact_sub_title c_display"
                  style={{ fontSize: "18px" }}
                >
                  애드머스 제휴문의
                </div>
                <div className="content_contact_sub_title c_display">
                  <div>
                    <p
                      className="content_contact_sub_title custom_showText"
                      style={{ fontSize: "15px" }}
                    >
                      <span>
                        아래 메일로 이름, 기업명, 전화번호, 회신 이메일 주소와
                        문의 내용을
                      </span>
                    </p>
                    <p
                      className="content_contact_sub_title custom_showText"
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        marginTop: "3px",
                        marginBottom: "7px",
                      }}
                    >
                      <span>
                        기입하여 발송해 주시면 담당자가 직접 전화로 안내해
                        드립니다.
                      </span>
                    </p>
                    <p
                      className="content_contact_sub_title custom_showText"
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        marginTop: "3px",
                      }}
                    >
                      <span>
                        사업 분야 상세 설명이 필요하실 경우 자료 요청 함께
                        해주시면 메일로 회신드립니다.
                      </span>
                    </p>
                    <p className="content_contact_sub_title custom_hideText">
                      <span
                        style={{
                          letterSpacing: "-1px",
                          fontSize: "15px",
                          lineHeight: "2",
                        }}
                      >
                        아래 메일로 이름, 기업명, 전화번호, 회신 이메일 주소와
                        문의 내용을 기입하여 발송해 주시면 담당자가 직접 전화로
                        안내해 드립니다.
                        <br />
                        사업분야 상세 설명이 필요하실 경우 자료 요청 함께
                        해주시면 메일로 회신드립니다
                      </span>
                    </p>
                    <p
                      className="content_contact_sub_title custom_text"
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        marginTop: "3px",
                      }}
                    >
                      <br />
                    </p>
                    <p
                      className="content_contact_sub_title custom_text"
                      style={{
                        letterSpacing: "0px",
                        textAlign: "center",
                        fontSize: "20px",
                        marginTop: "3px",
                        color: "rgb(0, 143, 255)",
                        width: "100%",
                      }}
                    >
                      <a href="mailto:contact@genieworks.net">
                        <strong
                          style={{
                            letterSpacing: "0px",
                            fontSize: "20px",
                            color: "rgb(0, 143, 255)",
                          }}
                        >
                          contact@genieworks.net
                        </strong>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default Modal;
