import { css } from "@emotion/react";

export const Content6Style = css`
  padding: 145px 0 162px 0;
  .content_main6_content {
    margin-top: 72px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0px 100px 0;
    .content_main_text {
      h3 {
        font-size: 18px;
        line-height: 1.5;
      }
    }
    .content_main6_content {
      margin-top: 30px;
    }
  }
`;
