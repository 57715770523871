/** @jsxImportSource @emotion/react */
import { Content2Style } from "./styled";

const Content2 = () => {
  return (
    <div className="content_main2" css={Content2Style}>
      <div className="container">
        <div className="content_main2_circle c_display">
          <img src={"images/main/bg_Oval_1.png"} alt="" />
        </div>
        <div className="content_main_text">
          <h2>가장 간단한 이벤트 만들기</h2>
          <p>
            몇 번의 클릭만으로 경품, 게임 이벤트를 생성하세요.
            <br className="c_display" />
            게임의 재미와
            <br className="m_display" />
            경품 당첨에 대한 기대감을 통해 고객 참여를 유도할 수 있습니다.
          </p>
          <button
            className="btn-line"
            onClick={() => window.open("https://manager.admerce.co.kr")}
          >
            더 알아보기
          </button>
        </div>
        <div className="content_main2_img">
          <img src={"images/main/main_img_2.png"} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Content2;
