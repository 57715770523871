/** @jsxImportSource @emotion/react */
import { MainStyle } from "./styled";

const Main = () => {
  return (
    <div css={MainStyle}>
      <div className="container">
        <div className="main_content">
          <div className="main_content_text">
            <h2>
              빠르고 쉬운
              <br className="m_display" />
              셀프 마케팅
              <br />
              애드머스
            </h2>
            <p className="c_display">
              애드머스는 광고 기획부터 소재 활용, 제작, 결과 확인까지
              <br />
              클릭 몇 번으로 손쉽게 가능한 셀프 서비스형 마케팅
              <br />
              자동화 플랫폼입니다.
            </p>
            <button
              className="btn-line c_display"
              onClick={() => window.open("https://manager.admerce.co.kr")}
            >
              시작하기
            </button>
          </div>
          <div className="main_content_img">
            <img src={"images/main/main_img.png"} alt="메인 콘텐츠 이미지" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
