/** @jsxImportSource @emotion/react */
import { Content6Style } from "./styled";

const Content6 = () => {
  return (
    <div className="content_main6" css={Content6Style}>
      <div className="container">
        <div className="content_main_text">
          <h3>
            국내외 주요 광고주가
            <br />
            애드머스와 함께하고 있습니다.
          </h3>
        </div>
        <div className="content_main6_content">
          <div className="content_main6_content_img">
            <img
              src={"images/main/patnership.png"}
              alt="콘텐츠6 이미지"
              className="c_display"
            />
            <img
              src={"images/main/patnership_m.png"}
              alt="콘텐츠6 모바일 이미지"
              className="m_display"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content6;
