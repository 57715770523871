/** @jsxImportSource @emotion/react */
import { Content1Style } from "./styled";

import parse from "html-react-parser";

const Content1 = () => {
  const Content1Data = [
    {
      id: 1,
      img: "images/main/main_2_icon_1.png",
      title: "대행사 의뢰",
      content:
        "별도 대행사 의뢰가 불필요하여 획기적으로<br />시간과 비용이 절약됩니다.",
    },
    {
      id: 2,
      img: "images/main/main_2_icon_2.png",
      title: "기획",
      content:
        "직관적인 이벤트 제작 tool을 활용하여<br />쉽게 광고를 기획 및 생성할 수 있습니다.",
    },
    {
      id: 3,
      img: "images/main/main_2_icon_3.png",
      title: "광고 소재 제작",
      content:
        "다양한 일반 게임과 나만의 커스텀 게임을<br />자유롭게 이벤트에 활용할 수 있습니다.",
    },
    {
      id: 4,
      img: "images/main/main_2_icon_4.png",
      title: "매체 집행",
      content:
        "생성된 이벤트 URL을 활용하여<br />다양한 매체에 이벤트 집행이 가능합니다.",
    },
    {
      id: 5,
      img: "images/main/main_2_icon_5.png",
      title: "경품구입 및 배송",
      content:
        "인기있는 경품을 저렴하게 구입하여<br />추첨 및 발송까지 한번에 가능합니다.",
    },
    {
      id: 6,
      img: "images/main/main_2_icon_6.png",
      title: "결과 확인",
      content:
        "실시간 리포팅을 통해 광고 집행 상황을 확인하고<br />광고효과를 극대화할 수 있습니다.",
    },
  ];

  return (
    <div className="content_main1" css={Content1Style}>
      <div className="container">
        <div className="content_main_text">
          <h2>쉽게 지금 바로 마케팅 시작</h2>
          <p>
            애드머스는 광고 기획부터 소재 활용, 제작, 결과 확인까지 클릭 몇
            번으로
            <br />
            손쉽게 가능한 셀프 서비스형 마케팅 자동화 플랫폼 입니다.
          </p>
        </div>
        <div className="content_main_content">
          <ul>
            {Content1Data.map((list) => (
              <li key={list.id} className="col2">
                <div className="content_main_content_img">
                  <img
                    src={`${list?.img}`}
                    alt={`content1 이미지 ${list.id}`}
                  />
                </div>
                <div className="content_main_content_text">
                  <h3>{list.title}</h3>
                  {parse(`<p className= "brText">${list.content} </p>`)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Content1;
