import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Main,
  Content1,
  Content2,
  Content3,
  Content4,
  Content5,
  Content6,
} from "../components/Main";

const IndexPage = () => {
  return (
    <>
      <Header />
      <Main />
      <Content1 />
      <Content2 />
      <Content3 />
      <Content4 />
      <Content5 />
      <Content6 />
      <Footer />
    </>
  );
};

export default IndexPage;
