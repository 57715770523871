import { css } from "@emotion/react";

export const Content4Style = css`
  padding: 147px 0 470px;
  background-image: linear-gradient(121deg, #0ed2ff, #009de9);
  position: relative;
  overflow: hidden;

  .content_main_text {
    h2 {
      color: #fff;
      font-weight: bold;
      span {
        font-size: 38px;
        vertical-align: top;
        margin-top: 3px;
        display: inline-block;
        font-weight: bold;
      }
    }
    p {
      color: #fff;
      line-height: 1.5;
      letter-spacing: 0.6px;
      font-weight: 300;
    }
  }
  .content_manager4_img {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -422px;
    img {
      float: left;
      margin-bottom: -1px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0 200px 0;
    .content_main_text {
      h2 {
        font-size: 19px;
      }
      p {
        font-size: 12px;
        letter-spacing: -1px;
      }
    }
    .content_manager4_img {
      margin-left: 0;
      left: 0;
      text-align: center;
      width: calc(100% - 24px);
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        max-width: 312px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
  }
`;
