/** @jsxImportSource @emotion/react */
import { Content8Style } from "./styled";

const Content8 = () => {
  return (
    <div className="content_manager8" css={Content8Style}>
      <div className="container2">
        <div className="content_main_text3">
          <h2>다양한 매체에 자유롭게 노출하세요!</h2>
          <p>
            제작된 이벤트 URL을 원하는 곳에 붙여 넣기만 하면
            <br className="m_display" />
            다양한 매체에 손쉽게 광고 송출!
          </p>
        </div>
        <ul className="content_manager8_list">
          <li className="col3">
            <img
              src={"images/manager/manager_9_illust_1.png"}
              alt="content8 이미지1"
            />
            <h3>MMS발송</h3>
            <p>문자 메세지에 삽입하여 발송하세요.</p>
            <div className="line">
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
            </div>
          </li>
          <li className="col3">
            <img
              src={"images/manager/manager_9_illust_2.png"}
              alt="content8 이미지2"
            />
            <h3>이벤트 렌딩페이지</h3>
            <p>배너 이미지 랜딩 URL로 사용하세요.</p>
            <div className="line">
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
              <div className="cicle"></div>
            </div>
          </li>
          <li className="col3">
            <img
              src={"images/manager/manager_9_illust_3.png"}
              alt="content8 이미지3"
            />
            <h3>게시물에 입력</h3>
            <p>각종 게시물에 URL을 입력해 보세요.</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Content8;
