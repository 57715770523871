import Header from "../components/Header";
import Footer from "../components/Footer";
import Terms1 from "../components/Terms1";

const Term1Page = () => {
  return (
    <>
      <Header />
      <Terms1 />
      <Footer />
    </>
  );
};

export default Term1Page;
