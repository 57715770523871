import { css } from "@emotion/react";

export const Content1Style = css`
  padding: 108px 0 113px 0;
  text-align: center;
  .content_manager_text {
    margin-top: 34px;
    h3 {
      font-size: 24px;
      color: #000;
      letter-spacing: -1.2px;
      font-weight: normal;
    }
    h4 {
      font-size: 36px;
      margin-top: 18px;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0 45px 0;
    .content_manager_img {
      img {
        width: 100%;
        max-width: 312px;
      }
    }
    .content_manager_text {
      margin-top: 25px;
      h3 {
        font-size: 12px;
      }
      h4 {
        font-size: 20px;
        margin-top: 15px;
      }
    }
  }
`;
