/** @jsxImportSource @emotion/react */
import { Content4Style } from "./styled";

const Content4 = () => {
  return (
    <div className="content_manager4" css={Content4Style}>
      <div className="container">
        <div className="content_main_text">
          <h2>캐주얼게임 이벤트 지원</h2>
          <p>
            고객의 흥미를 이끄는 다양한 캐쥬얼 게임을 이벤트 소재로 활용하세요.
            <br />
            재미를 통한 지속적인 이벤트 방문 효과와 더불어 게임 내 디자인 요소를
            <br />
            커스터마이징하여 브랜드 및 상품 인지도를 높일 수 있습니다.
          </p>
        </div>
        <div className="content_manager4_img">
          <img src={"images/manager/manager_img_2.png"} alt="content4 이미지" />
        </div>
      </div>
    </div>
  );
};

export default Content4;
