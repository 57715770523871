/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { Content5Style } from "./styled";
import useIndexStore from "../../Store/store";

const Content5 = () => {
  const navigate = useNavigate();
  const activeSetting = useIndexStore((state) => state.indexSetting);
  const Conten5Data = [
    {
      id: 1,
      usecase_index: 1,
      img: "images/main/main_usecase_1.png",
      m_img: "images/main/main_usecase_1_m.png",
    },
    {
      id: 2,
      usecase_index: 0,
      img: "images/main/main_usecase_2.png",
      m_img: "images/main/main_usecase_2_m.png",
    },
    {
      id: 3,
      usecase_index: 3,
      img: "images/main/main_usecase_3.png",
      m_img: "images/main/main_usecase_3_m.png",
    },
    {
      id: 4,
      usecase_index: 2,
      img: "/images/main/main_usecase_4.png",
      m_img: "images/main/main_usecase_4_m.png",
    },
  ];
  const handleToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const onClickList = (active: number) => {
    navigate("/use_case");
    activeSetting(active);
    handleToTop();
  };

  return (
    <div className="content_main5" css={Content5Style}>
      <div className="container">
        <div className="content_main_text">
          <h3>
            애드머스는 국내 정상급 광고주들과
            <br className="m_display" />
            함께
            <br className="c_display" />
            다양한 프로모션을 진행합니다.
          </h3>
        </div>
        <div className="content_main5_content">
          <ul>
            {Conten5Data.map((list) => (
              <li
                key={list.id}
                className="col4"
                onClick={() => onClickList(list.usecase_index)}
              >
                <img
                  src={`${list?.m_img}`}
                  alt={`content5 이미지 ${list.id}`}
                  className="m_display"
                />
                <img
                  src={`${list?.img}`}
                  alt={`content5 이미지 ${list.id}`}
                  className="c_display"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Content5;
