/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { UseCaseContentsStyle } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Pagination, EffectFade, Autoplay } from "swiper";

import parse from "html-react-parser";

interface UseCaseContentsProps {
  id: number;
}

const UseCaseContents = ({ id }: UseCaseContentsProps) => {
  const UseCaseContentsData = [
    {
      id: 1,
      logo: "images/usecase/1_bi.png",
      mainImg: "images/usecase/1_img.png",
      title: "올리브영 라운드어라운드<br />커스템게임 광고 집행",
      overviewTitle: "올리브영 라운드 어라운드 커스텀 광고 집행",
      overviewText:
        "올리브영 브랜드 ‘라운드어라운드’는 빙그레와 협업하여 바나나맛 우유 모양의<br class='c_display' />립밤, 바디워시,바디로션을 출시했습니다. 애드머스는 ’10, 20 대를 타겟으로 한 귀여운 상품’이라는<br class='c_display' />제품 특징과 게임을 즐겨 하는 타겟 고객의 수요를 결합시켜 높은 브랜드 및 제품 인식을 유도했습니다.",
      outputsText:
        " 애드머스 보유 게임인 ‘과일가게‘의 게임 아이콘을 올리브영 브랜드 제품으로 커스터마이징하여<br class='c_display' />캐주얼 게임을 즐기는 타겟 고객들에게 자연스럽고 지속적인 브랜드 및 제품 노출이<br class='c_display' />가능했습니다.",
      slideImg1: "images/usecase/1_thumbnail_1.png",
      slideImg2: "images/usecase/1_thumbnail_2.png",
    },
    {
      id: 2,
      logo: "images/usecase/2_bi.png",
      mainImg: "images/usecase/2_img.png",
      title: "듀오 신규 고객 유치를 위한<br />이벤트 광고 집행",
      overviewTitle: "듀오 신규 고객 유치를 위한 이벤트 광고 집행",
      overviewText:
        "듀오 신규 고객 유치를 위한 연애 진단 테스트, 이상형 매칭 이벤트를 진행했습니다.<br class='c_display' />애드머스는 듀오의 타겟 고객인 미혼 남,여를 대상으로  지속적인 광고 노출 및 부담 없고 캐주얼한 참여를<br class='c_display' />만들기 위해 다양한 보유 게임 활용 및 게임 점수 순, 랜덤 순 등의 당첨 로직을 통한 삼성 리워드 포인트<br class='c_display' />적립으로 높은 클릭률과 이벤트 노출을 유도했습니다.",
      outputsText:
        "단순한 매체 배너 광고가 아닌,  다양한 애드머스 보유 게임과 접목시키는 방식을 활용하여<br class='c_display' />고객들이 자발적으로 듀오 이벤트에 노출되고 참여할 수 있도록 유도하였습니다.",
      slideImg1: "images/usecase/2_thumbnail_1.png",
      slideImg2: "images/usecase/2_thumbnail_2.png",
    },
    {
      id: 3,
      logo: "images/usecase/3_bi.png",
      mainImg: "images/usecase/3_img.png",
      mainImgSize: "calc(100% - 60px) !important",
      title: "인피니티 스마트 패키지<br  />출시기념 광고 집행",
      overviewTitle: "인피니티 스마트 패키지 출시기념 광고 집행",
      overviewText:
        "인피니티는 스마트 패키치 출시 기념 크로스오버 라인업 시승 이벤트를 진행했습니다.<br class='c_display' />애드머스는 인피니티의 주요 타겟 고객의 니즈와 관심도를 파악하여 드라이빙 게임 이벤트를 선정하고<br class='c_display' />타겟 고객의 높은 호응을 유도했으며 다양한 당첨 방식 중 랜덤순 당첨 로직을 활용한 포인트 지급으로<br class='c_display' />지속적 참여를 이끌어 내어 이벤트 홍보 효과를 극대화 했습니다.",
      outputsText:
        "단순한 매체 배너 광고가 아닌, 다양한 애드머스 보유 게임과 접목시키는 방식을 활용하여<br class='c_display' />고객들이 자발적으로 인피니티 이벤트에 노출되고 참여할 수 있도록 유도하였습니다.",
      slideImg1: "images/usecase/3_thumbnail_1.png",
      slideImg2: "images/usecase/3_thumbnail_2.png",
    },
    {
      id: 4,
      logo: "images/usecase/4_bi.png",
      mainImg: "images/usecase/4_img.png",
      title: "KB국민카드<br  />계절 프로모션 광고 집행",
      overviewTitle: "KB국민카드 계절 프로모션 광고 집행",
      overviewText:
        "국민카드가 연말 계절 프로모션으로 진행한 ‘위시 페스티벌’ 이벤트의 광고를 집행했습니다.<br class='c_display' />애드머스는 지속적인 광고 노출을 통한 홍보효과를 얻기 위해 캐주얼 게임에 참여하면 랜덤 순으로<br class='c_display' />포인트를 지급하는 방식을 활용해 반복적 이벤트 노출 및 높은 클릭률을 유도했습니다.",
      outputsText:
        "단순한 매체 배너 광고가 아닌, 다양한 애드머스 보유 게임과 접목시키는 방식을 활용하여<br class='c_display' />고객들이 자발적으로 인피니티 이벤트에 노출되고 참여할 수 있도록 유도하였습니다.",
      slideImg1: "images/usecase/4_thumbnail_1.png",
      slideImg2: "images/usecase/4_thumbnail_2.png",
    },
  ];

  return (
    <div css={UseCaseContentsStyle}>
      <div key={UseCaseContentsData[id].id}>
        <div className="content_use">
          <div className="content_use_logo">
            <img
              src={UseCaseContentsData[id].logo}
              alt={`로고이미지 ${UseCaseContentsData[id].id}`}
            />
          </div>
          {parse(`<p className= "brText">${UseCaseContentsData[id].title}</p>`)}
          <div
            className="content_use_img"
            css={css`
              @media screen and (max-width: 600px) {
                width: ${UseCaseContentsData[id].mainImgSize};
                bottom: ${id === 2 ? "50px !important" : "0px"};
              }
            `}
          >
            <img
              src={UseCaseContentsData[id].mainImg}
              alt={`메인이미지 ${UseCaseContentsData[id].id}`}
            />
          </div>
        </div>
        <div className="content_use2">
          <div className="container container-left2">
            <div className="content_use_contentTitle">
              <h3>Overview</h3>
            </div>
            <div className="content_use_contentText">
              <h4 className="c_display">
                {UseCaseContentsData[id].overviewTitle}
              </h4>
              {parse(
                `<p className= "brText">${UseCaseContentsData[id].overviewText}</p>`
              )}
            </div>
          </div>
        </div>
        <div className="content_use3">
          <div className="container container-left2">
            <div className="content_use3_text">
              <div className="content_use_contentTitle">
                <h3>Outputs</h3>
              </div>
              <div className="content_use_contentText">
                {parse(
                  `<p className= "brText">${UseCaseContentsData[id].outputsText}</p>`
                )}
              </div>
            </div>
            <div className="content_use3_img swiper-container">
              <Swiper
                modules={[Pagination, EffectFade, Autoplay]}
                pagination={{
                  type: "fraction",
                }}
                effect={"fade"}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
              >
                <ul className="swiper-wrapper">
                  <SwiperSlide>
                    <li className="swiper-slide">
                      <img
                        src={UseCaseContentsData[id].slideImg1}
                        alt={`슬라이드이미지 ${UseCaseContentsData[id].id}`}
                      />
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li className="swiper-slide">
                      <img
                        src={UseCaseContentsData[id].slideImg2}
                        alt={`슬라이드이미지 ${UseCaseContentsData[id].id}+1`}
                      />
                    </li>
                  </SwiperSlide>
                </ul>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UseCaseContents;
