/** @jsxImportSource @emotion/react */
import { FooterStyle } from "./styled";

import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const goTerms2 = () => {
    navigate(`/terms2`);
  };
  const goTerms1 = () => {
    navigate(`/terms`);
  };
  return (
    <footer css={FooterStyle}>
      <div className="container">
        <ul className="footer_content">
          <li className="active " onClick={goTerms2}>
            <a className="c_display_inline">이용약관</a>
            <span className="terms1  m_display_inline">이용약관</span>
          </li>

          <li className="active" onClick={goTerms1}>
            <a className="c_display_inline">개인정보 처리 방침</a>
            <span className="terms2 m_display_inline">개인정보 처리 방침</span>
          </li>

          <li className="c_display_inline">대표자: 이앙</li>
          <li>
            서울특별시 강남구 논현로 653, 2층
            <span className="m_display_inline">
              , 사업자등록번호 109-88-0099
            </span>
          </li>
          <li className="c_display_inline">사업자등록번호 551-88-00073</li>
          <li>
            <a
              href="tel:1800-1011"
              style={{ color: "rgba(255, 2552, 255, 0.6)", fontSize: "11px" }}
            >
              <span className="m_display_inline">대표자: 이앙, </span> 대표전화
              1800-1011
            </a>
          </li>
        </ul>
        <div className="footer_copy">
          Copyright &copy; Genieworks. All rights reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
