import { css } from "@emotion/react";

export const Content9Style = css`
  padding: 153px 0 520px 0;
  background-image: linear-gradient(
    302deg,
    #fffe7a,
    #74c9f4 37%,
    #3492ff 65%,
    #004fc7
  );
  position: relative;
  overflow: hidden;
  .content_manager9_circle {
    position: absolute;
    top: -490px;
    left: 5px;
  }
  .content_manager9_circle2 {
    position: absolute;
    bottom: 70px;
    right: 180px;
  }
  .content_main_text3 {
    h2 {
      color: #fff;
    }
    p {
      font-size: 22px;
      color: #fff;
    }
  }

  .content_manager9_img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      float: left;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0 46% 0;
    .content_manager9_circle {
      display: none;
    }
    .content_manager9_circle2 {
      display: none;
    }
    .content_main_text3 {
      h2 {
        font-size: 18px;
      }
      p {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    .content_manager9_img {
      margin-left: 0;
      left: 0;
      text-align: center;
      width: calc(100% - 24px);
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        max-width: 312px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
  }
`;
