/** @jsxImportSource @emotion/react */
import { HeaderStyle } from "./styled";
import { Link } from "react-router-dom";

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";

const Header = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  //문의사항모달
  const [isOpenNav, setIsOpenNav] = useState(false);
  //화면이 600px이 되면 생기는 bars메뉴바가 사이드에서 보이도록 하기 위한 토글st

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => setIsOpenModal(false);
  const toggleNav = () => {
    setIsOpenNav(!isOpenNav);
  };

  return (
    <>
      <Modal isOpenModal={isOpenModal} onClose={closeModal} />
      <header css={HeaderStyle}>
        <div className="container">
          <div className="header_logo">
            <Link to="/">
              <img src={"/images/common/bi.png"} alt="로고" />
            </Link>
          </div>
          <div
            className="header_menu m_display"
            onClick={() => {
              setIsOpenNav(!isOpenNav);
            }}
          >
            <FontAwesomeIcon icon={faBars} className="bars" />
          </div>
          <div
            className="nav_header_wrap"
            onClick={toggleNav}
            style={{ display: isOpenNav ? "block" : "none" }}
          ></div>
          <nav style={{ right: isOpenNav ? "0" : "-100%" }}>
            <div className="nav_header m_display">
              <div className="nav_header_logo">
                <Link to="/">
                  <img src={"images/common/bi2.png"} alt="bi" title="bi" />
                </Link>
              </div>
              <FontAwesomeIcon
                icon={faXmark}
                className="nav_header_close"
                onClick={toggleNav}
              />
            </div>
            <ul className="nav_list">
              <li
                onClick={() => {
                  toggleNav();
                }}
              >
                <Link to="/manager">서비스</Link>
              </li>
              <li>
                <a
                  href="http://api-docs.admerce.co.kr/gamecenter"
                  target="_blank"
                >
                  API
                </a>
              </li>
              <li
                onClick={() => {
                  toggleNav();
                }}
              >
                <Link to="/use_case">성공사례</Link>
              </li>
              <li
                className="contact_link"
                onClick={() => {
                  openModal();
                  toggleNav();
                }}
              >
                문의하기
              </li>

              <li className="nav-button nav-button-line c_display">
                <a href="https://manager.admerce.co.kr" target="_blank">
                  애드머스 바로가기
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
