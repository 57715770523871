import { css } from "@emotion/react";

export const CommonStyle = css`
  .container {
    width: 1080px;
    margin: 0 auto;
    // position: relative;
    height: 100%;
  }
  .container2 {
    width: 1080px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    padding: 0 60px;
  }
  .container3 {
    width: 1080px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    padding: 0 90px;
  }
  .container-left {
    padding-left: 60px;
  }
  .container-left2 {
    padding-left: 90px;
  }

  .m_display {
    display: none;
  }
  .btn-line {
    border: 1px solid rgba(255, 255, 255, 0.65);
    color: #fff;
    background-color: transparent;
    .blue {
      color: #3b81fe;
      border: 1px solid #3b81fe;
    }
  }
  .col2 {
    width: 50%;
    float: left;
  }
  .col4 {
    width: 25%;
    float: left;
  }
  .col5 {
    width: 20%;
    float: left;
  }
  .col3 {
    width: calc(100% / 3 - 0.1px);
    float: left;
  }

  //content common css

  .content_main_text {
    text-align: center;
    h2 {
      font-size: 41px;
      line-height: 1;
      letter-spacing: -2px;
      font-weight: bold;
    }
    h3 {
      font-size: 38px;
      line-height: 56px;
      font-weight: bold;
    }

    p {
      font-size: 22px;
      margin-top: 25px;
      color: #000000;
      letter-spacing: -1px;
      font-weight: 300;
      line-height: 35px;
    }
    button {
      padding: 14px 0;
      width: 180px;
      border-radius: 27px;
      font-size: 18px;
      margin-top: 52px;
      position: relative;
      z-index: 10;
      font-weight: bold;
    }
  }
  .content_main_text2 {
    h3 {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: -0.7px;
    }
    p {
      color: #000;
      font-size: 18px;
      margin-top: 25px;
      line-height: 30px;
      font-weight: 300;
      letter-spacing: -0.5px;
    }
    span {
      font-size: 18px;
      margin-top: 20px;
      color: #3887ff;
      display: inline-block;
      font-weight: bold;
      img {
        margin-left: 9px;
        vertical-align: top;
        margin-top: 4px;
      }
    }
  }
  .content_main_text3 {
    text-align: center;
    h2 {
      font-size: 39px;
      font-weight: bold;
    }
    h3 {
      font-size: 20px;
      line-height: 1.7;
      span {
        font-size: 27px;
      }
    }
    p {
      font-size: 22px;
      margin-top: 15px;
      line-height: 1.7;
      font-weight: 300;
      letter-spacing: -1px;
    }
    button {
      padding: 16px 0;
      border-radius: 36px;
      font-size: 15px;
      margin-top: 40px;
      width: 180px;
      font-size: 18px;
    }
  }
  .content_main_content {
    margin-top: 64px;
    li {
      display: table-cell;
      vertical-align: middle;
      padding: 36px 0px;
      div {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .content_main_content_text {
    margin-left: 35px;
    h3 {
      font-size: 26px;
      color: #000000;
      font-weight: bold;
    }
    p {
      font-size: 18px;
      color: #000000;
      line-height: 28px;
      margin-top: 8px;
      font-weight: 300;
      letter-spacing: -0.5px;
    }
  }
  @media screen and (max-width: 600px) {
    .content_contact_sub_title .custom_showText {
      margin-top: 9px;
      display: none;
    }
    .content_contact_sub_title .custom_hideText {
      margin-top: 9px;
      display: block;
    }
    .container {
      width: 100%;
      overflow: hidden;
      padding: 0 24px;
    }
    .container2 {
      width: 100%;
      overflow: hidden;
      padding: 0 24px;
    }
    .c_display {
      display: none;
    }
    .m_display {
      display: block;
    }
    .m_display_inline {
      display: inline;
    }
    .c_display_inline {
      display: none;
    }
    .col2,
    .col4 {
      width: 100%;
    }
    .col5 {
      width: 100%;
    }
    .col3 {
      width: 100%;
    }
  }
`;
