import { css } from "@emotion/react";

export const ModalStyle = css`
  .contact {
    width: 100%;
    height: 100%;
    background-color: #f4f9fd;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  .contact_wrap {
    max-width: 1440px;
    margin: 0 auto;
    min-width: 1080px;
    width: 100%;
  }
  .contact_header {
    padding: 28px 72px;
    margin-top: 18px;
    display: inline-block;
    width: 100%;
  }
  .contact_header_logo {
    float: left;
  }
  .contact_header_close {
    float: right;
    cursor: pointer;
  }
  .contact_content {
    margin-top: 53px;
  }
  .contact_content_text {
    h2 {
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      line-height: 50px;
    }
    p {
      font-size: 18px;
      text-align: center;
      margin-top: 17px;
      color: #000;
      font-weight: 300;
      line-height: 30px;
    }
  }
  .content_contact_sub {
    margin-top: 68px;
    padding: 25px 0;
    border-top: 1px solid rgba(63, 68, 96, 0.2);
    border-bottom: 1px solid rgba(63, 68, 96, 0.2);
    display: inline-block;
    width: 100%;
  }
  .content_contact_sub_title {
    font-size: 16px;
    float: left;
    font-weight: normal;
    margin-top: 3px;
    width: 100%;
    text-align: center;
    display: block;
    line-height: 1.4;
    .custom_text {
      margin-top: 9px;
    }
    .custom_showText {
      margin-top: 9px;
      display: block;
      width: 100%;
    }
    .custom_hideText {
      margin-top: 9px;
      display: none;
    }
  }
  .content_contact_sub_text {
    font-size: 16px;
    float: right;
    color: rgba(63, 68, 96, 0.6);
  }
  @media screen and (max-width: 600px) {
    .container3 {
      width: 100%;
      padding: 0 24px;
    }
    .contact_header_close {
      img {
        width: 20px;
      }
    }
    .contact_header_logo {
      img {
        width: 100px;
      }
    }
    .contact_header {
      padding: 30px 24px;
      margin-top: 0;
    }
    .contact_wrap {
      min-width: 100%;
    }
    .contact_content {
      margin-top: 0;
    }
    .contact_content_text {
      h2 {
        font-size: 22px;
      }
      p {
        display: none;
      }
    }
    .content_contact_sub {
      border: 0;
      margin-top: 0;
      padding: 5px 0;
    }
    .content_contact_sub_title .custom_showText {
      margin-top: 9px;
      display: none;
    }
    .content_contact_sub_title .custom_hideText {
      margin-top: 9px;
      display: block;
    }
  }
`;
