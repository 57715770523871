import { css } from "@emotion/react";

export const Content1Style = css`
  padding: 160px 0 142px 0;
  display: inline-block;
  width: 100%;

  .content_main_content {
    margin-top: 64px;
    li {
      display: flex;
      align-items: center;
      padding: 36px 0px;
      div {
        display: block;
        vertical-align: middle;
        font-size: 18px;
        color: #000000;
        letter-spacing: -0.5px;
        line-height: 1.5;
      }
    }
  }
  .content_main_content_text {
    margin-left: 35px;
    h3 {
      font-size: 26px;
      color: #000000;
      font-weight: bold;
    }
    p {
      font-size: 18px;
      color: #000000;
      line-height: 28px;
      margin-top: 8px;
      font-weight: 300;
      letter-spacing: -0.5px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0 72px 0;
    .content_main_text {
      h2 {
        font-size: 19px;
      }
      p {
        font-size: 12px;
        line-height: 20px;
        margin-top: 20px;
      }
    }
    .content_main_content {
      margin-top: 44px;
      li {
        padding: 20px 0px;
        .content_main_content_img img {
          width: 60px;
      }
        .content_main_content_text {
          margin-left: 23px;
          h3 {
            font-size: 14px;
            font-weight: bold;
        } p {
          font-size: 12px;
          line-height: 1.5;
      }
      }
  }
  }
`;
