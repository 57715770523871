import { css } from "@emotion/react";

export const Content2Style = css`
  padding: 90px 0;
  background-color: #f7fafc;
  display: inline-block;
  width: 100%;

  .content_manager2_img {
    float: left;
    margin-top: 8px;
  }
  .content_manager2_text {
    float: right;
    div:first-of-type {
      margin-top: 0;
    }
    div {
      margin-top: 36px;
      h3 {
        font-size: 26px;
        font-weight: bold;
      }
      p {
        font-size: 18px;
        color: #000;
        margin-top: 16px;
        font-weight: 300;
        line-height: 27px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 42px 0;
    .content_manager2_text {
      div {
        img {
          float: left;
          width: 60px;
          margin-right: 20px;
        }

        h3 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
          line-height: 1.5;
          margin-top: 8px;
          padding-left: 80px;
        }
      }
    }
  }
`;
