/** @jsxImportSource @emotion/react */
import { Content5Style } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const Content5 = () => {
  const Content5Data = [
    {
      id: 1,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/54/logoImg/logo.png",
      title: "복어의습격",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/54/game/index.html?c=9999&preview=T",
    },
    {
      id: 2,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/53/logoImg/logo.png",
      title: "벽타고머니",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/53/game/index.html?c=9999&preview=T",
    },
    {
      id: 3,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/52/logoImg/logo.png",
      title: "푸시푸시캣",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/52/game/index.html?c=9999&preview=T",
    },
    {
      id: 4,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/51/logoImg/logo.png",
      title: "축구의신",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/51/game/index.html?c=9999&preview=T",
    },
    {
      id: 5,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/50/logoImg/logo.png",
      title: "카드뒤집기",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/50/game/index.html?c=9999&preview=T",
    },
    {
      id: 6,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/49/logoImg/logo.png",
      title: "풍선팡팡",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/49/game/index.html?c=9999&preview=T",
    },
    {
      id: 7,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/48/logoImg/logo.png",
      title: "해적다람쥐",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/48/game/index.html?c=9999&preview=T",
    },
    {
      id: 8,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/47/logoImg/logo.png",
      title: "스카이콩콩",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/47/game/index.html?c=9999&preview=T",
    },
    {
      id: 9,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/46/logoImg/logo.png",
      title: "농구의신",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/46/game/index.html?c=9999&preview=T",
    },
    {
      id: 10,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/45/logoImg/logo.png",
      title: "뇌섹퀴즈",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/45/game/index.html?c=9999&preview=T",
    },
  ];
  return (
    <div className="content_manager5" css={Content5Style}>
      <div className="container2">
        <div className="content_main_text2">
          <h3>일반 게임</h3>
          <p>
            자체 개발한 100여종 이상의 캐주얼 게임을 이용해보세요.
            <br />
            이벤트 당첨자 선정 및 경품 추첨이 쉽고 재밌어집니다.
            <br />
            게임을 먼저 체험해보고 진행하려는 이벤트에 맞는
            <br />
            최적의 게임을 골라 사용하실 수 있습니다.
          </p>
          <span>
            <span className="c_display_inline">우측</span>
            <span className="m_display_inline">아래</span>의 게임하기 버튼을
            눌러 샘플게임을 즐겨보세요.
            <img src={"images/manager/icon_arrow.png"} alt="화살표" />
          </span>
        </div>

        <Swiper
          className="content_manager5_list"
          navigation={true}
          modules={[Navigation]}
          spaceBetween={5}
          slidesPerView={"auto"}
          breakpoints={{
            960: {
              spaceBetween: 10,
            },
          }}
        >
          {Content5Data.map((list) => (
            <SwiperSlide
              key={list.id}
              className="content_manager5_list_container"
              onClick={() =>
                window.open(
                  `${list.link}`,
                  `${list.title}`,
                  "toolbar=no, width=400, height=700"
                )
              }
            >
              <div className="content_manager5_list_img">
                <img
                  src={`${list?.img}`}
                  alt={`content5 게임 리스트 이미지 ${list.id}`}
                />
              </div>
              <div className="content_manager5_list_text">
                <h4>{list.title}</h4>
              </div>
              <a
                className="content_manager5_list_button"
                href={list.link}
                onClick={() =>
                  window.open(
                    `${list.link}`,
                    `${list.title}`,
                    "toolbar=no, width=400, height=700"
                  )
                }
              >
                게임하기
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Content5;
