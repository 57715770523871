import { css } from "@emotion/react";

export const UseCaseContentsStyle = css`
  .content_use {
    padding: 140px 0 435px 0;
    text-align: center;
    position: relative;

    .brText {
      //.content_use_title와 같음
      margin-top: 44px;
      font-size: 40px;
      letter-spacing: -2px;
    }
    .content_use_img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      img {
        float: left;
      }
    }
  }

  .content_use2 {
    background-color: #f7fafc;
    height: 414px;
    padding-top: 126px;
  }
  .content_use_contentTitle {
    float: left;
    width: 233px;
    h3 {
      font-size: 27px;
      font-weight: 500;
      float: left;
    }
  }

  .content_use_contentText {
    float: left;
    margin-left: 43px;

    h4 {
      font-size: 26px;
      color: #000;
      font-weight: normal;
      margin-bottom: 30px;
    }
    .brText {
      font-size: 18px;
      color: #000;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: -1px;
    }
  }

  .content_use3 {
    padding: 108px 0 87px 0;
    display: inline-block;
    width: 100%;
    .content_use3_text {
      display: inline-block;
    }
    .content_use3_img {
      margin-top: 84px !important;
      clear: both;
    }
    .swiper-pagination {
      font-size: 23px !important;
      color: #fff !important;
      letter-spacing: -2px;
      text-align: right !important;
      padding-right: 25px;
      bottom: 20px !important;
      width: 900px !important;
      .swiper-pagination-current {
        font-size: 23px !important;
      }
      .swiper-pagination-total {
        font-size: 23px !important;
      }
    }
  }

  .content_use4 {
    padding: 144px 0 21px 0;
    background-color: #fff;
    background-image: linear-gradient(303deg, #475768, #323541);
  }
  .content_use4 .content_main_text h2 {
    color: #fff;
  }
  .content_use4 .content_main_text p {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }
  .content_use4_list ul {
    display: inline-block;
    width: 100%;
    margin-top: 72px;
  }
  .content_use4_list ul li:first-of-type {
    margin-left: 0;
  }
  .content_use4_list ul li {
    width: calc(100% / 3 - 36.1px);
    margin-left: 54px;
    float: left;
  }
  .content_use4_list ul li .content_use4_list_circle {
    width: 100%;
    height: 264px;
    background-color: rgba(0, 0, 0, 0.16);
    border-radius: 50%;
  }
  .content_use4_list ul li h5 {
    text-align: center;
    font-size: 21px;
    color: #fff;
    font-weight: bold;
    margin-top: 36px;
  }
  .content_use4_list_circle_text {
    font-size: 41px;
    color: #fff;
    text-align: center;
    line-height: 263px;
  }
  .content_use4_list_circle_ctr {
    padding-top: 105px;
    padding-left: 35px;
  }
  .content_use4_list_circle_ctr
    > div:first-of-type
    .content_use4_list_circle_ctr_text_cont {
    font-size: 39px;
    color: #fff;
  }
  .content_use4_list_circle_ctr
    > div:first-of-type
    .content_use4_list_circle_ctr_text_title {
    color: rgba(255, 255, 255, 0.7);
  }
  .content_use4_list_circle_ctr_text {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }
  .content_use4_list_circle_ctr_text_cont {
    font-size: 23px;
    color: rgba(255, 255, 255, 0.5);
  }
  .content_use4_list_circle_ctr_text_title {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
  }
  .content_use4_list_circle_ctr .line {
    display: inline-block;
    margin-left: 1px;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -24px;
  }
  .content_use4_list_circle_ctr .line .cicle {
    width: 4px;
    height: 4px;
    background-color: #c5c5c5;
    float: left;
    margin-left: 4px;
    border-radius: 50%;
  }
  @media screen and (max-width: 600px) {
    .content_use {
      padding: 40px 0 260px 0;
      .content_use_logo {
        img {
          height: 20px;
        }
      }

      .brText {
        margin-top: 10px;
        font-size: 19px;
      }
      .content_use_img {
        width: 220px;
        img {
          width: 100%;
          float: none;
        }
      }
    }
    .content_use2 {
      text-align: center;
      padding: 40px 0;
      height: auto;
      .container {
        padding: 0 24px;
        .content_use_contentTitle {
          width: 100%;
          float: none;
          h3 {
            float: none;
            font-size: 19px;
          }
        }
        .content_use_contentText {
          margin-left: 0;
          float: none;

          .brText {
            margin-top: 11px;
            font-size: 11px;
            line-height: 1.7;
          }
        }
      }
    }
    .content_use3 {
      text-align: center;
      padding: 40px 0px;
      .container {
        padding: 0 24px;
        .content_use_contentTitle {
          width: 100%;
          h3 {
            float: none;
            font-size: 19px;
          }
        }
        .content_use_contentText {
          margin: 0 auto;

          .brText {
            margin-top: 11px;
            font-size: 11px;
            line-height: 1.7;
          }
        }
        .content_use3_img {
          margin-top: 20px !important;
          img {
            width: 100%;
          }
        }
        .swiper-pagination {
          width: 100% !important;
          font-size: 15px !important;
          bottom: 15px !important;
          padding-right: 15px;
          .swiper-pagination-current {
            font-size: 15px !important;
          }
          .swiper-pagination-total {
            font-size: 15px !important;
          }
        }
      }
    }
  }
`;
