import { css } from "@emotion/react";

export const FooterStyle = css`
  padding: 36px 0;
  background-color: #23252d;
  .m_display_inline {
    display: none;
  }
  .footer_content {
    width: 100%;
    display: inline-block;
    clear: both;

    li:first-of-type {
      margin-left: 0;
    }
    li {
      float: left;
      font-size: 11px;
      color: rgba(255, 2552, 255, 0.6);
      margin-left: 30px;
      a {
        cursor: pointer;
      }
    }
    li:last-of-type {
      float: right;
    }
    .active {
      color: #fff;
      a {
        color: #fff;
        font-size: 11px;
      }
    }
  }
  .footer_copy {
    font-size: 11px;
    color: rgba(255, 2552, 255, 0.6);
    margin: 8px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 18px 0;
    .m_display_inline {
      display: inline;
      font-size: 10px;
    }
    .c_display_inline {
      display: none;
    }
    .footer_content {
      li {
        font-size: 10px;
        transform: scale(0.8);
        transform-origin: left;
        margin-left: 0px;
      }
      li:last-of-type {
        float: left;
      }
      a {
        font-size: 10px;
      }
    }
    .footer_copy {
      font-size: 10px;
      margin: 0;
      transform: scale(0.8);
      transform-origin: left;
    }
  }
`;
