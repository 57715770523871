import { css } from "@emotion/react";

export const MainStyle = css`
  width: 100%;
  height: 720px;
  background-image: linear-gradient(
    298deg,
    #fffe7a,
    #74c9f4 37%,
    #3492ff 65%,
    #004fc7
  );
  padding-top: 120px;
  .main_content {
    height: 600px;
    padding-top: 60px;
    position: relative;
    top: 30px;
    transform: translateY(0);
  }
  .main_content_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -60px;
    h2 {
      font-size: 40px;
      color: #fff;
      font-weight: bold;
      line-height: 55px;
      letter-spacing: -2px;
    }
    p {
      font-size: 17px;
      margin-top: 27px;
      color: #fff;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: -0.53px;
      span {
        letter-spacing: 0.8px;
      }
    }
    button {
      padding: 15px 0;
      border-radius: 36px;
      font-size: 18px;
      margin-top: 47px;
      width: 180px;
    }
  }
  .main_content_img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  @media screen and (max-width: 600px) {
    height: 360px;
    padding-top: 54px;
    .container {
      width: 100%;
      overflow: hidden;
      padding: 0 24px;
    }
    .main_content {
      padding-top: 5px;
    }
    .main_content_text {
      position: static;
      top: 0;
      margin-top: 0;
      transform: translateY(0);
      h2 {
        font-size: 23px;
        line-height: 32px;
      }
    }
    .main_content_img {
      top: 20px;
      right: -6px;
      transform: translateY(0);
      img {
        width: 246px;
      }
    }
  }
`;
