/** @jsxImportSource @emotion/react */
import { Content3Style } from "./styled";

const Content3 = () => {
  const Conten3Data = [
    {
      id: 1,
      num: "01",
      title: "회원가입",
      content: "방문 고객 회원전환",
    },
    {
      id: 2,
      num: "02",
      title: "브랜딩",
      content: "게임 브랜드 믹스",
    },
    {
      id: 3,
      num: "03",
      title: "리텐션",
      content: "정교한 데이터 타겟팅",
    },
    {
      id: 4,
      num: "04",
      title: "매출 증대",
      content: "그룹별 맞춤 메시지",
    },
  ];
  return (
    <div className="content_main3" css={Content3Style}>
      <div className="container">
        <div className="content_main_text">
          <h3>
            카카오싱크
            <br className="m_display" />
            <br className="c_display" />
            100배 활용 프로모션
          </h3>
          <p>카카오 싱크와 에드머스가 만나 시너지 효과를 나타냅니다.</p>
          <button
            className="btn-line blue"
            onClick={() => window.open("http://kakaosync.admerce.co.kr")}
          >
            더 알아보기
          </button>
        </div>
        <div className="content_main3_content">
          <ul>
            {Conten3Data.map((list) => (
              <li key={list.id} className="col4">
                <div className="info">
                  <div className="info_num">{list.num}</div>
                  <div className="info_title">{list.title}</div>
                  <div className="info_text">{list.content}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Content3;
