import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Main,
  Content1,
  Content2,
  Content3,
  Content4,
  Content5,
  Content6,
  Content7,
  Content8,
  Content9,
} from "../components/Manager";

const ManagerPage = () => {
  return (
    <>
      <Header />
      <Main />
      <Content1 />
      <Content2 />
      <Content3 />
      <Content4 />
      <Content5 />
      <Content6 />
      <Content7 />
      <Content8 />
      <Content9 />
      <Footer />
    </>
  );
};

export default ManagerPage;
